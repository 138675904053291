import {
    Container,
    Grid,
    Box,
    Avatar,
    Typography,
    Button,
} from "@mui/material";


const Heading = ({ backgroundImageUrl, shopImageUrl }) => {
    return <Box sx={{
        border: '1px solid #000',
        backgroundImage: `url(${backgroundImageUrl})`, // Use template literals to reference the variable
        backgroundSize: 'cover',  // Adjust to your needs
        backgroundPosition: 'center center',  // Adjust to your needs
        width: "100%",
        height: "150px", // Adjust to your preferred height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderRadius: "8px"
    }}>
        <img
            src={shopImageUrl}
            alt="Your Image"
            style={{
                width: "75px", // Adjust to your preferred size
                height: "75px", // Adjust to your preferred size
                borderRadius: "50%", // Clip as a circle
                objectFit: "cover",
              }}
        />
    </Box>
}

export default Heading;