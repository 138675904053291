import {
    Container,
    Grid,
    Box,
    Avatar,
    Typography,
    Button,
} from "@mui/material";

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import NetworkWifi3BarOutlinedIcon from '@mui/icons-material/NetworkWifi3BarOutlined';

const SubInfo = ({ address, phone, wifi }) => {
    return <Box sx={{ border: '1px solid transparent', color:'grey' }}>
        <Grid container spacing={2}>
            <Grid item >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOnOutlinedIcon sx={{ marginRight: 1 }}/>
                    <Typography variant="subtitle2" component="span">
                        {address}
                    </Typography>
                </Box>
            </Grid>
            <Grid item >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocalPhoneOutlinedIcon sx={{ marginRight: 1 }}/>
                    <Typography variant="subtitle2" component="span">
                        {phone}
                    </Typography>
                </Box>
            </Grid>
            <Grid item >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <NetworkWifi3BarOutlinedIcon sx={{ marginRight: 1 }}/>
                    <Typography variant="subtitle2" component="span">
                        {wifi}
                    </Typography>
                </Box>
            </Grid>
        </Grid>


    </Box>
}

export default SubInfo;