import { createAsyncThunk} from '@reduxjs/toolkit';
import { createAnalyticApi } from './analyticAPI';

export const createAnalyticAsyncThunk = createAsyncThunk(
  'analytic/create',
  async ({  click_type, click_id  }) => {
    console.log("createAnalyticAsyncThunk started");
    const response = await createAnalyticApi({ click_type, click_id });
    console.log("createAnalyticAsyncThunk response");
    console.log(response);
    return response.data;
  }
);