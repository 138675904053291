import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';

const HorizontalCardList = ({ itemList }) => {
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" >
            {itemList.map((item, index) => (
                <Grid item key={index}>
                    <Card style={{  maxWidth:"150px", padding: "8px" }}>
                        {/* <img src="https://qrmenu.sirv.com/Images/qr-menu/menus/menu-2.jpeg" style={{ width: "100%" }} /> */}
                        <img src={item.background_image_url} style={{ width: "100%" }} />
                        <Typography variant="span" component="h6" sx={{ color: 'black', textAlign: 'center' }}>
                            { item.name }
                        </Typography>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}


export default HorizontalCardList;