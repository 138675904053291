import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  articleSelectApi,
  articleDetailSelectApi,
  voteCreateApi,
} from "../articles/articleAPI";
import { shopByNameSelectApi } from "./menuAPI";

const imageUrl = "https://oddmenu.com/_nuxt/img/default-bg1.ccc3227.svg";
// ဒါက Initial State
const initialState = {
  shop: {
    id: 0,
    name: "Shop Name",
    description: "Shop Description. Very long text. may be paragraph.",
    address: "Address",
    contact: "Phone",
    background_image_url: imageUrl,
    shop_image_url: imageUrl,
    wifi_name: "Wifi Name",
    wifi_password: "WifiPassw0rd",
    url: "default-shop",
    visit_count: 0,
    categories: [
      {
        id: 0,
        name: "Main Menu",
        selected: true,
        menus: [
          {
            id: 1,
            name: "Breakfast",
            background_image_url: imageUrl,
            selected: true,
            items: [
              {
                id: 0,
                name: "အုန်းနို့်ခေါက်ဆွဲ",
                image_url: imageUrl,
                description: "အလွတ် - ၂၅၀၀",
                price: "4200",
                currency: "MMK",
              },
              {
                id: 2,
                name: "ရှမ်းခေါက်ဆွဲ",
                image_url: imageUrl,
                description: "ရှမ်းပြည်နယ်အရသာ",
                price: "3400",
                currency: "MMK",
              },
            ],
          },
          {
            id: 2,
            name: "Dinner",
            background_image_url: imageUrl,
            selected: false,
            items: [
              {
                id: 0,
                name: "Dinner One",
                image_url: imageUrl,
                description: "အလွတ် - ၂၅၀၀",
                price: "4200",
                currency: "MMK",
              },
              {
                id: 2,
                name: "Dinner Tw3o",
                image_url: imageUrl,
                description: "ရှမ်းပြည်နယ်အရသာ",
                price: "3400",
                currency: "MMK",
              },
            ],
          },
        ],
      },

      {
        id: 1,
        name: "Supplimentry Menu",
        menus: [
          {
            id: 3,
            name: "အဖျော်ယမကာ",
            background_image_url: imageUrl,
            selected: true,
            items: [
              {
                id: 0,
                name: "အုန်းနို့်ခေါက်ဆွဲ",
                image_url: imageUrl,
                description: "အလွတ် - ၂၅၀၀",
                price: "4200",
                currency: "MMK",
              },
              {
                id: 2,
                name: "ရှမ်းခေါက်ဆွဲ",
                image_url: imageUrl,
                description: "ရှမ်းပြည်နယ်အရသာ",
                price: "3400",
                currency: "MMK",
              },
            ],
          },
          {
            id: 4,
            name: "အအေး",
            background_image_url: imageUrl,
            selected: false,
            items: [
              {
                id: 0,
                name: "Dinner One",
                image_url: imageUrl,
                description: "အလွတ် - ၂၅၀၀",
                price: "4200",
                currency: "MMK",
              },
              {
                id: 2,
                name: "Dinner Tw3o",
                image_url: imageUrl,
                description: "ရှမ်းပြည်နယ်အရသာ",
                price: "3400",
                currency: "MMK",
              },
            ],
          },
        ],
      },
    ],
  },
  status: "idle", // idle, loading, error , succeded
  error: null,
  page: 1,
  should_load: false,
  prev_page: 0,

  cart: [
    /*
    {
      item_id: 1,
      quantity: 2,
      item: {
        id: 0,
        name: "Dinner One",
        background_image_url: imageUrl,
        description: "အလွတ် - ၂၅၀၀",
        price: 4200,
        currency: "MMK",
      },
    },
    {
      item_id: 2,
      quantity: 4,
      item: {
        id: 2,
        name: "Dinner Two",
        background_image_url: imageUrl,
        description: "အလွတ် - ၂၅၀၀",
        price: 1200,
        currency: "MMK",
      },
    },
    */
  ],

  dine_mode : 0, // 0 mean dine in, 1 means parcel

  search_query: null,
  search_result: [], // list of dish

  active_menu_id: null,
  active_category_id: null,
  // search_query: "",

  article: {},
  article_status: "idle",
  article_error: null,
};

// Thunks

export const selectMenuByShopNameAsyncThunk = createAsyncThunk(
  "menus/shop_name",
  async ({ shop_name }, { getState }) => {
    const state = getState(); // <-- invoke and access state object
    if (state.menus.shop.url == shop_name) {
      throw new Error("Whoops! already loaded... ");
    }
    const response = await shopByNameSelectApi({ shop_name });
    return response.data;
  }
);

// Slice
export const menuSlice = createSlice({
  name: "menus",
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    updateSelectedCategory: (state, action) => {
      const category_id = action.payload.id;
      // un select all menu
      // find and update
      for (let i = 0; i < state.shop.categories.length; i++) {
        for (let j = 0; j < state.shop.categories[i].menus.length; j++) {
          state.shop.categories[i].menus[j].selected = false;
        }
      }

      // find and update
      for (let i = 0; i < state.shop.categories.length; i++) {
        if (state.shop.categories[i].id === category_id) {
          state.shop.categories[i].selected = true;
          // now select first menu also
          if (state.shop.categories[i].menus.length > 0) {
            state.shop.categories[i].menus[0].selected = true;
          }
        } else {
          state.shop.categories[i].selected = false;
        }
      }
    },
    updateSelectedMenu: (state, action) => {
      const menu_id = action.payload.id;
      // find and update
      for (let i = 0; i < state.shop.categories.length; i++) {
        for (let j = 0; j < state.shop.categories[i].menus.length; j++) {
          if (state.shop.categories[i].menus[j].id == menu_id) {
            state.shop.categories[i].menus[j].selected = true;
          } else {
            state.shop.categories[i].menus[j].selected = false;
          }
        }
      }
    },
    updateSearchQuery: (state, action) => {
      const { search_query } = action.payload;
      state.search_query = search_query;
      console.log("updateSearchQuery");
      console.log(search_query.length);
      // now filter the search result
      state.search_result = [];
      if (search_query.length != 0) {
        for (let i = 0; i < state.shop.categories.length; i++) {
          let category = state.shop.categories[i];
          for (let j = 0; j < category.menus.length; j++) {
            let menu = category.menus[j];
            for (let k = 0; k < menu.items.length; k++) {
              let item = menu.items[k];
              if (
                item.name
                  .toLowerCase()
                  .indexOf(state.search_query.toLowerCase()) != -1
              ) {
                state.search_result.push(item);
              }
            }
          }
        }
      }
    },
    addToCart: (state, action) => {
      const { item_id } = action.payload; // item id
      console.log('item id ' + item_id);
      let item = null;
      if (item_id !== undefined && item_id !== 0) {
        // find cart_item
        console.log("finding item");
        for (let i = 0; i < state.shop.categories.length; i++) {
          let category = state.shop.categories[i];
          for (let j = 0; j < category.menus.length; j++) {
            let menu = category.menus[j];
            for (let k = 0; k < menu.items.length; k++) {
              if (menu.items[k].id === item_id) {
                item = menu.items[k];
                // TODO: need to skip this loop
              }
            }
          }
        }
      }
      console.log("item is");
      console.log(item);
      console.log(item.id);
      console.log(item.name);
      // insert into cart
      if (item !== null) {
        // TODO: need to check quantity , since this item is already in cart or not?
        // ရှာ မတွေ့ရင် undefined ပြမယ်။ array1.find((element) => element < 1);
        /*
        let existing_cart = state.cart.findIndex(
          (cart_item) => cart_item.item_id === id
        );
        */
        // Find the index of the object with the specified item_id, မတွေ့ရင် -1 ပြန်မယ်။
        // const index = itemList.findIndex((item) => item.item_id === itemId);
        console.log("finding index in cart");
        const index = state.cart.findIndex(
          (cart_item) => cart_item.item_id === item_id
        );
        console.log("cart index");
        console.log(index);
        if (index === -1) {
          // insert new cart item
          let quantity = 1;
          state.cart.push({ item_id, quantity, item });
        } else {
            state.cart[index].quantity++;
        }
      }
    },
    reduceFromCart: (state, action) =>{
      const { item_id } = action.payload;
      const index = state.cart.findIndex(
        (cart_item) => cart_item.item_id === item_id
      );
      console.log("cart index");
      console.log(index);
      if (index > -1) {
        // reduce or remove
        state.cart[index].quantity--;
        if(state.cart[index].quantity <= 0){
          // colors.splice(carIndex, 1); // colors = ["red","blue","green"]
          state.cart.splice(index,1);
        }

      }

    },
    increaseToCart: (state, action) =>{
      const { item_id } = action.payload;
      const index = state.cart.findIndex(
        (cart_item) => cart_item.item_id === item_id
      );
      console.log("cart index");
      console.log(index);
      if (index > -1) {
        // reduce or remove
        state.cart[index].quantity++;
      }

    },
    setDineMode: (state, action) =>{
      state.dine_mode = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      // for analytic, we dont need to do anyting to listen
      // detail api listener
      .addCase(selectMenuByShopNameAsyncThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(selectMenuByShopNameAsyncThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.shop = action.payload;
        if (state.shop.categories.length > 0) {
          state.shop.categories[0].selected = true;
          if (state.shop.categories[0].menus.length > 0) {
            state.shop.categories[0].menus[0].selected = true;
          }
        }
      })
      .addCase(selectMenuByShopNameAsyncThunk.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message; // Store the error message
      });
  },
});

// actions
export const {
  reset,
  updateSelectedMenu,
  updateSelectedCategory,
  updateSearchQuery,
  addToCart,
  reduceFromCart,
  increaseToCart,
  setDineMode
} = menuSlice.actions;

// selectors

export const selectShopInfo = (state) => state.menus.shop;
export const selectCart = (state) => state.menus.cart;
export const selectDineMode = (state) => state.menus.dine_mode;

export const selectCartTotal = (state) => {
  let cartTotal = 0;
  // sum total
  for(let i=0; i < state.menus.cart.length; i++){
    cartTotal += state.menus.cart[i].item.price * state.menus.cart[i].quantity;
  }
  // calculate tax
  let tax = cartTotal * 0.05;
  cartTotal += tax;
  return cartTotal;
};
/*
export const selectShopInfo = (state) => {
    // တကယ်လို့ state.shop မရှိခဲ့ရင်?
    // TODO: ဒါကို handle လုပ်ဖို့ စဉ်းစားထားရမယ်။
    return {
        id : state.menus.shop.id,
        background_image_url : state.menus.shop.background_image_url,
        shop_image_url : state.menus.shop.shop_image_url,
        name : state.menus.shop.name,
        address : state.menus.shop.address,
        phone : state.menus.shop.phone,
        wifi_name : state.menus.shop.wifi_name,
        wifi_password : state.menus.shop.wifi_password
    }
};
*/
export const selectShopStatus = (state) => state.menus.status;
export const selectShopError = (state) => state.menus.error;
export const selectCategories = (state) => state.menus?.shop?.categories ?? [];
export const selectSearchQuery = (state) => state.menus.search_query;
export const selectSearchResult = (state) => state.menus.search_result;
// selected ဖြစ်နေတဲ့ menu ထဲက category တွေကို လိုချင်တာ။

// export const selectCategoryList = (state) => state.menus.shop.menus;
/*
export const selectSelectedCategories = (state) =>
    state.menus.shop.menus.reduce((categories, menu) =>
        menu.selected ? categories.concat(menu.categories) : categories, []);
        */

export const selectSelectedMenus = (state) =>
  state?.menus?.shop?.categories?.reduce(
    (menus, category) =>
      category.selected ? menus.concat(category.menus) : menus,
    []
  ) ?? [];
// state?.menus?.shop?.menus?.reduce((categories, menu) => (menu.selected ? categories.concat(menu.categories) : categories), []) ?? [];

// Get the active menu and category
// export const selectActiveMenu = (state) => state.menus.shop.menus.find(menu => menu.selected );
// export const selectActiveCategory = (state) => state.menus.shop.menus.find(menu => menu.selected ).categories.find(category => category.selected );
// Get the list of dishes for the selected category
// error handling also included using ?
export const selectSelectedItemList = (state) =>
  state?.menus?.shop?.categories
    .find((category) => category.selected)
    ?.menus.find((menu) => menu.selected)?.items ?? [];
export const selectSelectedMenu = (state) =>
  state?.menus?.shop?.categories
    .find((category) => category.selected)
    ?.menus.find((menu) => menu.selected) ?? { name: "Selected Category" };
// state.menus.shop.menus.find(menu => menu.selected).categories.find(category => category.selected).dishes;

export const selectRecommendedItemList = (state) => [
  {
    id: 1,
    name: "ကြေးအိုး ဆီချက်",
    description: "ကြက် ၊​ ဝက် ၊​ အမဲ",
    background_image_url:
      "https://qrmenu.sirv.com/Images/qr-menu/menus/menu-1.jpeg",
    old_price: 0,
    price: 8500,
    currency: "MMK",
    current_price: 8500,
    quantity: 1,
    is_active: 1,
    menu_id: 1,
    created_at: "2023-12-15T02:14:44.000000Z",
    updated_at: "2023-12-15T02:14:44.000000Z",
    media: [
      {
        id: 15,
        file_name: "picture15.png",
        file_url: "picture15.png",
        file_type: "picture",
        file_size: 1,
        created_at: "2023-12-15T02:14:44.000000Z",
        updated_at: "2023-12-15T02:14:44.000000Z",
      },
    ],
  },
  {
    id: 2,
    name: "မာလာ ကြေးအိုး (ပွဲသေး)",
    description: "မာလာ ၊​ မာလာ အစပ်လျော့",
    background_image_url:
      "https://qrmenu.sirv.com/Images/qr-menu/menus/menu-2.jpeg",
    old_price: 0,
    price: 14000,
    currency: "MMK",
    current_price: 14000,
    quantity: 1,
    is_active: 1,
    menu_id: 1,
    created_at: "2023-12-15T02:14:44.000000Z",
    updated_at: "2023-12-15T02:14:44.000000Z",
    media: [
      {
        id: 16,
        file_name: "picture16.png",
        file_url: "picture16.png",
        file_type: "picture",
        file_size: 1,
        created_at: "2023-12-15T02:14:44.000000Z",
        updated_at: "2023-12-15T02:14:44.000000Z",
      },
    ],
  },
  {
    id: 3,
    name: "တုန်ယမ်း မာလာ",
    description: "အချို ၊​ အစပ်",
    background_image_url:
      "https://qrmenu.sirv.com/Images/qr-menu/menus/menu-3.jpeg",
    old_price: 10,
    price: 20000,
    currency: "MMK",
    current_price: 20000,
    quantity: 1,
    is_active: 1,
    menu_id: 1,
    created_at: "2023-12-15T02:14:44.000000Z",
    updated_at: "2023-12-15T02:14:44.000000Z",
    media: [
      {
        id: 17,
        file_name: "picture17.png",
        file_url: "picture17.png",
        file_type: "picture",
        file_size: 1,
        created_at: "2023-12-15T02:14:44.000000Z",
        updated_at: "2023-12-15T02:14:44.000000Z",
      },
    ],
  },
];
// ဒါက Gloal State ထဲက ယူပြရမှာ။
// Combined Reducer ခေါ်မလားပဲ။
// export reducer
// ဒါက combined reducer က ယူသုံးဖို့ ထင်ရဲ့။
export default menuSlice.reducer;
