import {
    Container,
    Grid,
    Box,
    Avatar,
    Typography,
    Button,
    Card,
} from "@mui/material";
import Chip from '@mui/material/Chip';


import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
    // thunks
    // selectors
    // actions
    updateSelectedCategory,
    updateSearchQuery
} from '../../../../features/menu/menuSlice';



/*
 လိုတာကတော့ Menu Object Array နဲ့ callback လုပ်ရမယ့် / dispatch လုပ်ရမယ့် Action ပဲ
 မရှုပ်ချင်ရင် Selector နဲ့တောင်ရတယ်။
*/
// menus is string array
const CategoryList = ({ categories }) => {
    // core
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    // event hanlder
    const onTapHandler = (id) => {
        console.log("onTapHandler");
        console.log(id);
        dispatch(updateSelectedCategory({ id }));

        // clear search 

        dispatch(updateSearchQuery({ search_query: "" }));
        // should we go back or not check 
        // if 
        const currentRoute = location.pathname;
        console.log(currentRoute);
        if (currentRoute === "/dishes") {
            // go back
            // navigate("/"); 
            navigate(-1);
        }
    }

    return <Box sx={{ border: '1px solid transparent' }}>
        <Grid container spacing={2}>
            {categories.map((category, index) => (
                <Grid item key={index}>
                    <Container
                        onClick={() => onTapHandler(category.id)}
                        textAlign="center"
                        // top-right and bottom-left corners
                        style={{
                            border: '1px solid #ddd',
                            padding: '10px',
                            borderRadius: '0px 16px 0px 16px',
                            backgroundColor: category.selected ? "lightblue" : "white",  // Set your desired background color
                            cursor: 'pointer',  // Change cursor on hover
                            
                        }}  // Optional: Add border and padding for styling
                    >
                        <Typography>
                            {category.name}
                        </Typography>
                    </Container>
                    {/* <Chip label={category.name} color="primary" variant={category.selected ? "filled" : "outlined"} onClick={() => onTapHandler(category.id)} /> */}
                    {/* <Box
                        onClick={() => onTapHandler(menu.id)}
                        sx={{

                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #000',
                            margin: '2px',
                            padding: '4px',
                            borderRadius: '8px',
                            background: menu.selected ? 'black' : 'white',
                            color: menu.selected ? 'white' : 'black',
                            cursor: "pointer"
                        }}>
                        <Typography variant="subtitle2" component="span">
                            {menu.name}
                        </Typography>
                    </Box> */}
                </Grid>
            ))}
        </Grid>


    </Box>
}

export default CategoryList;