import {
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ContactUsButton from "./contact-us-button";
import { Link as ScrollLink } from "react-scroll";
import { useSelector } from "react-redux";
import { selectContactUs, selectProjects } from "../../../../../features/landing/landingSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

/* ဘာတွေ ပြမှာလဲ? */
/* Intro Section ကလိုပဲ ပုံနဲ့စာကို တစ်ပိုင်းစီပြမယ် */
/* Left Project နဲ့ Right Project ဆိုပြီး သုံးမယ်။ */
/*
    Infromation အနေနဲ့

    1. title
    2. description
    3. demo con

    4. Image 

*/

const LeftProject = ({ title, description, image }) => {
  const contactUs = useSelector(selectContactUs);

  return (
    <Grid
      container
      spacing={2}
      sx={{ backgroundColor: "transparent", height: "100vh" }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"column"}
          fullwidth
          sx={{ backgroundColor: "transparent" }}
        >
          <Typography sx={{ fontSize: "36px", marginBottom: "24px", fontFamily:"MyanmarPhetsot"  }}>
            {title}
          </Typography>

          <span style={{ fontSize: "20px", lineHeight:"2"}}> {description} </span>
          {/* <Typography fontFamily="MyanmarPhetsot">{description}</Typography> */}
          <ScrollLink
            to={"contact"}
            spy={true}
            smooth={true}
            duration={1000}
            sx={{ cursor: "pointer", border: "1px solid grey" }}
          >
            <Button
              sx={{
                color: "blue",
                border: "1px solid transparent",
                margin: "0",
                padding: "0",
                marginTop: "16px",
              }}
            >
              { contactUs }
            </Button>
          </ScrollLink>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img alt="intro section" src={image} width={"100%"} />
      </Grid>
    </Grid>
  );
};

const RightProject = ({ title, description, image }) => {
  const contactUs = useSelector(selectContactUs);
  return (
    <Grid
      container
      spacing={2}
      sx={{ backgroundColor: "transparent", height: "100vh" }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img alt="intro section" src={image} width={"100%"} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"column"}
          fullwidth
          sx={{ backgroundColor: "transparent" }}
        >
          <Typography sx={{ fontSize: "36px", marginBottom: "24px" , fontFamily:"MyanmarPhetsot" }}>
            {title}
          </Typography>
          <span style={{ fontSize: "20px", lineHeight:"2"}}> {description} </span>
          {/* <Typography fontFamily={"MyanmarPhetsot"}>{description}</Typography> */}
          <ScrollLink
            to={"contact"}
            spy={true}
            smooth={true}
            duration={1000}
            sx={{ cursor: "pointer", border: "1px solid grey" }}
          >
            <Button
              sx={{
                color: "blue",
                border: "1px solid transparent",
                margin: "0",
                padding: "0",
                marginTop: "16px",
              }}
            >
              {contactUs}
            </Button>
          </ScrollLink>
        </Stack>
      </Grid>
    </Grid>
  );
};
function ProjectSection() {
  const desktopScreen = useMediaQuery("(min-width:700px)");
  // global state
  const { title, projects } = useSelector(selectProjects);

  return (
    <>
      <Grid item xs={12} textAlign={"center"} id="projects">
        <Typography variant="h4" p={10} fontFamily={"MyanmarPhetsot"}>
          {title}
        </Typography>
      </Grid>
      {projects.map((project, index) =>
        (index % 2 === 0 || desktopScreen === false ) ? (
          <LeftProject
            title={project.title}
            description={project.description}
            image={project.image}
          />
        ) : (
          <RightProject
            title={project.title}
            description={project.description}
            image={project.image}
          />
        )
      )}
      {/* ဒီ အချက်အလက်တွေကိုလည်း json နဲ့ လုပ်ထားရမယ် */}
      {/* data နဲ့ layout ကို ကွဲကွဲပြားပြား ဖြစ်အောင်လို့ */}
      {/* English / Myanmar နှစ်ဘာသာနဲ့ ပြနိုင်အောင် လုပ်ထားရမယ်။  */}
      {/* redux toolkit က select နဲ့တင် ရမယ် */}

{/*       
      <LeftProject
        title="QR Menu System"
        description="The QR Menu system simplifies dining with instant access to menus through QR code scans. This touchless solution prioritizes safety and efficiency, providing a streamlined and modern dining experience."
        image="./assets/images/qr-menu.png"
      />
      {desktopScreen ? (
        <RightProject
          title="Parcel Solution"
          description="Parcel Solution simplifies logistics, ensuring efficient and secure deliveries. Streamlining the shipping process, it offers businesses a reliable and effective tool for managing parcels seamlessly. Optimize your delivery operations with Parcel Solution's advanced features."
          image="./assets/images/project-one.png"
        />
      ) : (
        <LeftProject
          title="Parcel Solution"
          description="Parcel Solution simplifies logistics, ensuring efficient and secure deliveries. Streamlining the shipping process, it offers businesses a reliable and effective tool for managing parcels seamlessly. Optimize your delivery operations with Parcel Solution's advanced features."
          image="./assets/images/project-one.png"
        />
      )}
      <LeftProject
        title="Pandora App"
        description="Discover a vibrant online community with our app, reminiscent of Reddit. Engage in diverse discussions, share your thoughts, and explore a plethora of topics. Stay connected and informed in a dynamic and user-friendly platform that fosters meaningful conversations."
        image="./assets/images/pandora.webp"
      />
      {desktopScreen ? (
        <RightProject
          title="Customer Champion App"
          description="The Customer Champion app is your dedicated tool for delivering exceptional customer service. With streamlined communication features, it empowers businesses to engage with customers, address concerns, and build lasting relationships. Elevate your customer support experience with the Customer Champion app."
          image="./assets/images/customer-champion.avif"
        />
      ) : (
        <LeftProject
          title="Customer Champion App"
          description="The Customer Champion app is your dedicated tool for delivering exceptional customer service. With streamlined communication features, it empowers businesses to engage with customers, address concerns, and build lasting relationships. Elevate your customer support experience with the Customer Champion app."
          image="./assets/images/customer-champion.avif"
        />
      )} */}
    </>
  );
}

export default ProjectSection;
