import {
  Container,
  Grid,
  Box,
  Avatar,
  Typography,
  Button,
  MenuList,
  Divider,
  Fab,
} from "@mui/material";
import { ArrowForward, Navigation, RestaurantMenu } from "@mui/icons-material";
import { Link } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import {
  // selectors
  loggedInUser,
  loginStatus,
} from "./../../../features/user/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { useParams } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  // thunks
  selectArticlesAsyncThunk,
  // selectors
  selectArticles,
  selectArticlesPage,
} from "./../../../features/articles/articleSlice";

import Heading from "./components/Heading";
import Title from "./components/Title";
import SubInfo from "./components/SubInfo";
import CategoryList from "./components/CategoryList";
import SearchBar from "./components/SearchBar";
import MenuCard from "./components/MenuCard";

import {
  // thunks
  selectMenuByShopNameAsyncThunk,
  // selectors
  selectShopInfo,
  selectShopStatus,
  selectCategories,
  selectSelectedMenus,
  // actions
  updateSelectedMenu,
  updateSelectedCategory,
  selectRecommendedItemList,
  selectSelectedItemList,
  updateSearchQuery,
  selectCart,
} from "./../../../features/menu/menuSlice";
import LoadingCenter from "./components/LoadingCenter";
import HorizontalCardList from "./components/HorizontalCardList";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import FormHelperText from "@mui/material/FormHelperText";
import DishCard from "./components/DishCard";
import { MenuHorizontalList } from "./uiv3/components/menu-list";
import DishCardV3 from "./components/DishCardV3";
import { createAnalyticAsyncThunk } from "../../../features/analytic/analyticSlice";

// sample state data

const background_image_url =
  "https://oddmenu.com/_nuxt/img/default-bg1.ccc3227.svg";
const shop_image_url = "./loading.jpg";
// "https://dyj6gt4964deb.cloudfront.net/images/8866102348321223.jpeg";

const title = "Astro Brew House";
const address = "3090 Khatta(8) St. Ottarathiri";
const phone = "09441177996";
const wifi = "wifiP@ssw0rd";
const menus = ["Main Menu", "Bar", "Cafe"];
const categoryImageUrl =
  "https://dyj6gt4964deb.cloudfront.net/images/4874660711012869.jpeg";
const categoryTitle = "BREAKFAST";
const categoryTwoImageUrl =
  "https://dyj6gt4964deb.cloudfront.net/images/d14e2392-aa27-47f8-849f-d9d1f9930ebc.jpeg";
const categoryTwoTitle = "LIGHT FOOD";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const MenuThreeHomePage = () => {
  // core
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  // selector hooks

  // route parameter
  let { name } = useParams();
  // shop section

  const shopInfo = useSelector(selectShopInfo);
  const shopStatus = useSelector(selectShopStatus);
  const categoryList = useSelector(selectCategories);
  const menuList = useSelector(selectSelectedMenus);
  const itemList = useSelector(selectSelectedItemList);
  const cart = useSelector(selectCart);

  const commendedItemList = useSelector(selectRecommendedItemList);

  useEffect(() => {
    console.log("shop name is");
    console.log(name);
    const shop_name = name ?? "shop-one";
    console.log(shop_name);
    dispatch(selectMenuByShopNameAsyncThunk({ shop_name }));
  }, []); // Include history as a dependency

  const [age, setAge] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleCategoryChange = (event) => {
    let id = event.target.value;
    setAge(event.target.value);
    // this is category id
    dispatch(updateSelectedCategory({ id }));
    // clear search
    dispatch(updateSearchQuery({ search_query: "" }));
    // disptach analytic event
    let click_type = "category";
    let click_id = id;
    dispatch(createAnalyticAsyncThunk({ click_type, click_id }));
  };

  const renderLoading = (
    <LoadingCenter
      shopImageUrl={shop_image_url}
      backgroundImageUrl={background_image_url}
    />
  );

  const renderHeader = (
    <Container
      fullWidth
      sx={{
        backgroundColor: "#f5f5f5",
        backgroundImage: `url(${shopInfo.background_image_url})`,
        backgroundSize: "cover", // or "contain" based on your preference
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ color: "white", pt: 5, pb: 3, backgroundColor: "red" }}
      ></Typography>
      <Typography
        sx={{
          color: "white",
          padding: 1,
          margin: 0.5,
          borderRadius: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        {shopInfo.name}
      </Typography>
      <Typography
        sx={{
          color: "white",
          padding: 1,
          margin: 0.5,
          borderRadius: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        }}
      >
        {shopInfo.description}
      </Typography>
      <Typography
        sx={{
          color: "white",
          padding: 1,
          margin: 0.5,
          borderRadius: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        }}
      >
        {shopInfo.address}
      </Typography>
      <Typography
        sx={{
          color: "white",
          padding: 1,
          margin: 0.5,
          borderRadius: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        }}
      >
        {shopInfo.contact}
      </Typography>
    </Container>
  );

  /* input အနေနဲ့ categoryList */
  const renderDropdown = (
    <Container fullWidth sx={{ backgroundColor: "transparent" }}>
      <FormControl
        fullWidth
        sx={{ m: 0, minWidth: 120, backgroundColor: "transparent" }}
      >
        <InputLabel id="category">Choose Menu</InputLabel>
        <Select
          labelId="category"
          id="category"
          value={
            categoryList.length > 0 && age === 0 ? categoryList[0].id : age
          }
          label="Choose Menu"
          onChange={handleCategoryChange}
        >
          {/* <MenuItem value="">
            <em>ရွေးချယ်ပါ</em>
          </MenuItem> */}
          {/* {itemList.map((dish) => (<></>))} */}
          {categoryList.map((category) => (
            <MenuItem value={category.id}>{category.name}</MenuItem>
          ))}

          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
      <br></br>
    </Container>
  );

  const renderMenuList = (
    <>
      {menuList.length === 0 ? (
        <Container
          sx={{
            backgroundColor: "transparent",
            textAlign: "center",
            padding: "24px",
          }}
        >
          <span>No Menu Available</span>
        </Container>
      ) : (
        <></>
      )}
      <MenuHorizontalList menus={menuList} />
      {/* <CategoryList categories={categoryList} /> */}

      {/* <Container sx={{ borderBottom: 1, borderColor: "divider", backgroundColor:"blue" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="basic tabs example"
        >
          <Tab label="Item One" {...a11yProps(0)} />
          <Tab label="Item Two" {...a11yProps(1)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Three" {...a11yProps(2)} />
        </Tabs>
      </Container> */}
    </>
  );

  const renderItemList = (
    <>
      {itemList.length === 0 ? (
        <Container
          sx={{
            backgroundColor: "trnasparent",
            textAlign: "center",
            color: "grey",
          }}
        >
          <h4>No Item Available</h4>
        </Container>
      ) : (
        <></>
      )}
      <br></br>
      {itemList.map((dish, index) => (
        <Container mt={2}>
          <DishCardV3 dish={dish} even={index % 2} />
        </Container>
      ))}
    </>
  );

  const renderDishFab = (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Link to="./cart">
        <Fab variant="extended">
          <RestaurantMenu sx={{ mr: 1 }} />
          My Dishes ( {cart.length} )
        </Fab>
      </Link>
    </div>
  );

  const renderMain = (
    <>
      {renderHeader}
      <br></br>
      {renderDropdown}
      <br></br>
      {renderMenuList}
      <br></br>

      {menuList.length === 0 ? <></> : renderItemList}

      {/* spacer for fab*/}
      <Container height="72px" sx={{ marginTop: "72px", color: "white" }}>
        .
      </Container>

      {/* cart , call to action button */}

      {cart.length > 0 && renderDishFab }
    </>
  );

  const renderMainOrg = (
    <>
      {/* main container */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // height: '100vh',
          padding: "8px",
          border: "1px solid transparent",
          backgroundColor: "blue",
        }}
      >
        {/* center container */}
        <Box
          sx={{
            // defining width with 2 properties
            // flexGrow: 1,
            maxWidth: "sm",
            backgroundColor: "red",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper
            elevation={8}
            sx={{ backgroundColor: "transparent", width: "100%" }}
          >
            {renderHeader}

            <br></br>
            {renderDropdown}

            {/* <br></br> */}
            {/* <Divider /> */}
            {/* <br></br> */}

            {renderMenuList}
            {/* <br></br> */}

            {/* <Divider /> */}

            {menuList.length === 0 ? <></> : renderItemList}
          </Paper>
        </Box>
      </Box>
    </>
  );

  const renderOrg = (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        // height: '100vh',
        padding: "8px",
        border: "1px solid transparent",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: "sm",

          padding: "16px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add boxShadow for elevation
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h4" component="h2" sx={{ color: "black" }}>
              {shopInfo.name}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="span" sx={{ color: "black" }}>
              The Most Popular Items
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <HorizontalCardList itemList={commendedItemList} />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="span" sx={{ color: "black" }}>
              Choose your menu plz.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <CategoryList categories={categoryList} />
          </Grid>
          <Grid item xs={12}>
            <SearchBar />
          </Grid>

          {/* need to map */}
          {menuList.length === 0 ? (
            <Container sx={{ textAlign: "center" }}>
              No menu available - center
            </Container>
          ) : (
            ""
          )}
          {itemList.map((dish) => (
            <Grid item xs={12} key={dish.id}>
              {/* ဒီ Field  က လိုအပ်တဲ့ ဟာတွေအကုန်လုံးကို spreator / object လိုက် ပေးလိုက်ရင်လည်း မဆိုးဘူး */}
              {/* <DishCard dish={dish} imageUrl={dish.image_url} title={dish.name} subTitle={dish.description} price={dish.price} currency={dish.currency}/> */}
              <DishCard dish={dish} />
            </Grid>
          ))}
          {/* <Grid item xs={12}>
                <CategoryCard categoryImageUrl={categoryImageUrl} title={categoryTitle} />
            </Grid>
            <Grid item xs={12}>
                <CategoryCard categoryImageUrl={categoryTwoImageUrl} title={categoryTwoTitle} />
            </Grid>
            <Grid item xs={12}>
                <CategoryCard categoryImageUrl={categoryImageUrl} title={categoryTitle} />
            </Grid> */}

          {/* <Grid item xs={8}>
                <Item>xs=8</Item>
            </Grid>
            <Grid item xs={4}>
                <Item>xs=4</Item>
            </Grid>
            <Grid item xs={12}>
                <Item>xs=12</Item>
            </Grid> */}
        </Grid>
      </Box>
    </div>
  );

  // Loading Handler
  if (shopStatus === "loading") return renderLoading;

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            backgroundColor: "transparent",
            flexGrow: 1,
            maxWidth: "sm",
            minWidth: "200px",
          }}
        >
          {renderMain}
        </Box>
      </div>
    </>
  );
};
export default MenuThreeHomePage;
