import { Button, Container, Grid, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { useSelector } from "react-redux";
import { selectContacts } from "../../../../../features/landing/landingSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function _openLink(link) {
  if (link.startsWith("mailto:")) {
    // If it's an email link, open the default email client
    window.location.href = link;
  } else if (link.startsWith("tel:")) {
    // If it's a phone link, open it in the default phone app
    window.location.href = link;
  } else if (link.startsWith("viber:")) {
    // If it's a Viber link, open it in the Viber app
    window.location.href = link;
  } else if (link.startsWith("tg:")) {
    // If it's a Telegram link, open it in the Telegram app
    window.location.href = link;
  } else if (link.startsWith("fb-messenger:")) {
    // If it's a Messenger link, open it in the Messenger app
    window.location.href = link;
  } else {
    console.log("open in external link");
    // If it's a regular link, open it in a new window or tab
    window.open(link, "_blank");
  }
}
const ContactCard = ({ image, name, data, link }) => {
  return (
    <Grid
      id="contact"
      item
      xs={6}
      sm={4}
      md={3}
      textAlign={"center"}
      onClick={() => {
        _openLink(link);
      }}
    >
      <Container
        sx={{
          border: "2px solid lightgrey",
          padding: "20px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "lightgrey",
          },
        }}
      >
        <img src={`./assets/images/${image}`} alt={`${name}`} height={"100"} />
        <Typography p={2} component={"h2"} sx={{ fontWeight: "bold" }} fontFamily={"MyanmarPhetsot"}>
          {" "}
          {name}{" "}
        </Typography>
        <Typography sx={{ color: "grey" }} fontFamily={"MyanmarPhetsot"}> {data} </Typography>
      </Container>
    </Grid>
  );
};

function ContactUs() {
  // global state
  const { title, contacts } = useSelector(selectContacts);
  return (
    <>
      <Grid container spacing={2} id="services" mb={10}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h4" p={10} fontFamily={"MyanmarPhetsot"}>
            {title}
          </Typography>
        </Grid>
        {/* list of card */}
        {contacts.map((contact) => (
          <ContactCard
            image={contact.image}
            name={contact.title}
            data={contact.description}
            link={contact.url}
          />
        ))}

        {/* <ContactCard
          image="email.png"
          name="Email"
          data="admin@email.com"
          link="mailto:example@example.com"
        />
        <ContactCard
          image="phone.png"
          name="Phone"
          data="09963170133"
          link="tel:+1234567890"
        />
        <ContactCard
          image="viber.png"
          name="Viber"
          data="09963170133"
          link="viber://chat?number=+1234567890"
        />
        <ContactCard
          image="messenger.png"
          name="Messenger"
          data="Mm Software 100"
          link="https://fb.me/mmsoftware100"
        /> */}
      </Grid>
    </>
  );
}

export default ContactUs;
