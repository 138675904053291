import {
    Container,
    Grid,
    Box,
    Avatar,
    Typography,
    Button,
} from "@mui/material";


const Title = ({ title }) => {
    return <Box sx={{ border: '1px solid transparent' }}>
        <Typography variant="h2" component="h2">
            {title}
        </Typography>
    </Box>
}

export default Title;