import NavigationBar from "./components/navigation-bar";
import IntroSection from "./components/intro-section";
import { Box } from "@mui/material";
import OurServicesSection from "./components/our-services-section";
import ProjectSection from "./components/project-section";
import ContactUs from "./components/contact-us";

// import '../../App.css';

function HomePage() {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: 'center', padding:'20px', fontFamily: "Anton"  }}>
        <Box sx={{ maxWidth: "lg", minWidth:'200px', backgroundColor:'transparent',flexGrow:1 }}>
          <NavigationBar />
          <IntroSection />
          <OurServicesSection />
          <ProjectSection />
          <ContactUs />
        </Box>
      </Box>
    </>
  );
}

export default HomePage;
