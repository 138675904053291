import {
  Grid,
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  Fab,
  Stack,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import {
  // selectors
  selectCategories,
  selectSelectedItemList,
  selectSelectedCategory,
  selectShopInfo,
  selectSelectedMenu,
  selectCart,
  selectDineMode,
  setDineMode,
  selectCartTotal,
} from "./../../../features/menu/menuSlice";

import Heading from "./components/Heading";
import Title from "./components/Title";
import SubInfo from "./components/SubInfo";
import CategoryList from "./components/CategoryList";
import SearchBar from "./components/SearchBar";
import MenuCard from "./components/MenuCard";
import DishCard from "./components/DishCard";
import CartItemCard from "./components/CartItemCard";
import {
  Backspace,
  Cancel,
  Close,
  CropSquareSharp,
  RestaurantMenu,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// sample state data
/*
const backgroundImageUrl = "https://oddmenu.com/_nuxt/img/default-bg1.ccc3227.svg";
const shopImageUrl = "https://dyj6gt4964deb.cloudfront.net/images/8866102348321223.jpeg";
const title = "Astro Brew House";
const address = "3090 Khatta(8) St. Ottarathiri";
const phone = "09441177996";
const wifi = "wifiP@ssw0rd";
const menus = ["Main Menu", "Bar", "Cafe"];
const categoryImageUrl = "https://dyj6gt4964deb.cloudfront.net/images/4874660711012869.jpeg";
const categoryTitle = "BREAKFAST";
const categoryTwoImageUrl = "https://dyj6gt4964deb.cloudfront.net/images/d14e2392-aa27-47f8-849f-d9d1f9930ebc.jpeg";
const categoryTwoTitle = "LIGHT FOOD";

*/
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CartListPage = () => {
  // core
  const dispatch = useDispatch();

  // internal state
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);

  // selector hooks
  const cart = useSelector(selectCart);
  const dineMode = useSelector(selectDineMode);
  const cartTotal = useSelector(selectCartTotal);

  const navigate = useNavigate();

  // useEffects
  useEffect(() => {
    console.log("this is use effect for initial render");
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const activeModeStyle = {
    // backgroundColor: "lightgreen",
    textAlign: "center",
    padding: "12px",
    border: "1px solid green",
    borderRadius: "8px",
    color: "black",
    fontWeight: "bold",
    backgroundColor: "white",
    cursor: "pointer",
  };
  const unactiveModeStyle = {
    // backgroundColor: "lightgreen",
    textAlign: "center",
    padding: "12px",
    // border: "1px solid green",
    borderRadius: "8px",
    color: "grey",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const handleDineModeUpdate = (mode) => {
    dispatch(setDineMode(mode));
  };

  const handleClose = () => {
    setConfirmDialogShow(false);
  };

  const renderDishFab = (
    <Grid container>
      <Grid item xs={6}>
        <Typography>Total (inc Tax)</Typography>
      </Grid>

      <Grid item xs={6} sx={{ padding: "0px", margin: "0px" }}>
        <Container
          sx={{
            padding: "0px",
            backgroundColor: "transparent",
            textAlign: "right",
          }}
        >
          {/* need to format number as cash */}
          <Typography variant="body1" fontWeight="bold">
            {cartTotal.toLocaleString()} MMK
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );

  const renderOrderConfirmDialog = (
    <Dialog
      open={confirmDialogShow}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">အော်ဒါအတည်ပြုရန်</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          အတည်ပြုရန် Waiter လာပါမည်။
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            backgroundColor: "transparent",
            flexGrow: 1,
            maxWidth: "sm",
            minWidth: "200px",
            padding: "8px",
          }}
        >
          {/* heading */}
          <Grid container justifyContent="space-between" alignItems={"center"}>
            {/* title */}
            <Grid item xs={6} padding={1}>
              <h2>Your Dishes</h2>
            </Grid>
            {/* close icon */}
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ paddingRight: "0px" }}
              >
                <IconButton
                  onClick={handleGoBack}
                  sx={{ color: "red", textAlign: "center" }}
                  aria-label="close"
                >
                  <Container
                    sx={{
                      width: "8px", // Adjust the height as needed
                      borderRadius: "8px", // Half of the width or height for a perfect circle
                      border: "1px solid red", // Optional: Add a border for better visibility
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Box shadow
                    }}
                  >
                    <Close />
                  </Container>
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          {/* mode choice ဆိုင်ထိုင် / ပါစယ် */}
          <Grid
            container
            sx={{
              backgroundColor: "rgba(0,0,0,0.0)",
              padding: "8px",
            }}
          >
            <Grid item xs={6}>
              <Container
                sx={dineMode === 0 ? activeModeStyle : unactiveModeStyle}
                onClick={() => handleDineModeUpdate(0)}
              >
                ဆိုင်ထိုင်
              </Container>
            </Grid>
            <Grid item xs={6}>
              <Container
                sx={dineMode === 1 ? activeModeStyle : unactiveModeStyle}
                onClick={() => handleDineModeUpdate(1)}
              >
                ပါစယ်
              </Container>
            </Grid>
          </Grid>

          {/* cart list */}
          {cart.map((cart_item) => (
            <>
              {/* <h4>{cart_item.item.name}</h4> */}
              <CartItemCard cart_item={cart_item} />
              <Divider></Divider>
            </>
          ))}

          {/* spacer for fab*/}
          <Container height="72px" sx={{ marginTop: "80px", color: "white" }}>
            .
          </Container>

          {/* Confirm Button */}
          <Container
            sx={{
              marginTop: "24px",
              paddingTop: "24px",
              paddingBottom: "24px",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "white",
            }}
          >
            {renderDishFab}

            <Button
              variant="contained"
              fullWidth
              sx={{ marginTop: "8px", padding: "8px" }}
              onClick={() => setConfirmDialogShow(true) }
            >
              Confirm Order
            </Button>
          </Container>

          {/* spacer for fab*/}
          <Container height="72px" sx={{ marginTop: "8px", color: "white" }}>
            .
          </Container>
        </Box>
      </div>

      {renderOrderConfirmDialog}
    </>
  );
};
export default CartListPage;
