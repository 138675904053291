import {
    Grid,
    Box,
    Typography,
    Container,
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



import {
    // selectors
    selectCategories,
    selectSelectedItemList,
    selectSelectedCategory,
    selectShopInfo,
    selectSelectedMenu
} from './../../../features/menu/menuSlice';


import Heading from "./components/Heading";
import Title from "./components/Title";
import SubInfo from "./components/SubInfo";
import CategoryList from "./components/CategoryList";
import SearchBar from "./components/SearchBar";
import MenuCard from "./components/MenuCard";
import DishCard from "./components/DishCard";


// sample state data
/*
const backgroundImageUrl = "https://oddmenu.com/_nuxt/img/default-bg1.ccc3227.svg";
const shopImageUrl = "https://dyj6gt4964deb.cloudfront.net/images/8866102348321223.jpeg";
const title = "Astro Brew House";
const address = "3090 Khatta(8) St. Ottarathiri";
const phone = "09441177996";
const wifi = "wifiP@ssw0rd";
const menus = ["Main Menu", "Bar", "Cafe"];
const categoryImageUrl = "https://dyj6gt4964deb.cloudfront.net/images/4874660711012869.jpeg";
const categoryTitle = "BREAKFAST";
const categoryTwoImageUrl = "https://dyj6gt4964deb.cloudfront.net/images/d14e2392-aa27-47f8-849f-d9d1f9930ebc.jpeg";
const categoryTwoTitle = "LIGHT FOOD";

*/
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



const MenuDishListPage = () => {
    // core
    const dispatch = useDispatch();


    // selector hooks
    const categoryList = useSelector(selectCategories);
    const itemList = useSelector(selectSelectedItemList);
    const category = useSelector(selectSelectedMenu);
    const shopInfo = useSelector(selectShopInfo);

    // useEffects
    useEffect(() => {
        console.log("this is use effect for initial render");
    }, []);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            // height: '100vh',
            padding: '8px',
            border: "1px solid transparent"
        }}>
            <Box sx={{
                flexGrow: 1,
                maxWidth: 'sm',
                // border: "5px solid red",
                padding: '16px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add boxShadow for elevation
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* <Heading shopImageUrl={shopImageUrl} backgroundImageUrl={backgroundImageUrl} /> */}
                        <Heading shopImageUrl={shopInfo.shop_image_url} backgroundImageUrl={shopInfo.background_image_url} />


                    </Grid>

                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>
                        <Title title={shopInfo.name} />
                    </Grid>
                    <Grid item xs={12}>
                        <SubInfo address={shopInfo.address} phone={shopInfo.phone} wifi={shopInfo.wifi_password} />
                    </Grid>

                    <Grid item xs={12}>
                        <CategoryList categories={categoryList} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" component="h2">
                            {category.name}
                        </Typography>
                    </Grid>

                    {/* need to map -> dishList */}
                    {itemList.length == 0 ? <Container>No dish available</Container> : ""}
                    {itemList.map((dish) => (
                        <Grid item xs={12} key={dish.id}>

                            {/* ဒီ Field  က လိုအပ်တဲ့ ဟာတွေအကုန်လုံးကို spreator / object လိုက် ပေးလိုက်ရင်လည်း မဆိုးဘူး */}
                            {/* <DishCard dish={dish} imageUrl={dish.image_url} title={dish.name} subTitle={dish.description} price={dish.price} currency={dish.currency}/> */}
                            <DishCard dish={dish} />
                        </Grid>
                    ))}



                    {/* <Grid item xs={12}>
                        <DishCard imageUrl={categoryImageUrl} title={"အုန်းနို့ခေါက်ဆွဲ"} subTitle={"အလွတ် - ၂၅၀၀"} price={"၄,၂၀၀ ကျပ်"} />
                    </Grid>
                    <Grid item xs={12}>
                        <CategoryCard categoryImageUrl={categoryImageUrl} title={categoryTitle} />
                    </Grid>
                    <Grid item xs={12}>
                        <CategoryCard categoryImageUrl={categoryTwoImageUrl} title={categoryTwoTitle} />
                    </Grid>
                    <Grid item xs={12}>
                        <CategoryCard categoryImageUrl={categoryImageUrl} title={categoryTitle} />
                    </Grid>

                    <Grid item xs={8}>
                        <Item>xs=8</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>xs=4</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>xs=4</Item>
                    </Grid>
                    <Grid item xs={8}>
                        <Item>xs=8</Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>xs=12</Item>
                    </Grid> */}
                </Grid>
            </Box>
        </div>
    );
}
export default MenuDishListPage;