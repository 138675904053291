import {
    Container,
    Grid,
    Box,
    Avatar,
    Typography,
    Button,
} from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';



const LoadingCenter = ({ backgroundImageUrl, shopImageUrl }) => {
    return <Box sx={{
        // backgroundImage: `url(${backgroundImageUrl})`, // Use template literals to reference the variable
        backgroundSize: 'cover',  // Adjust to your needs
        backgroundPosition: 'center center',  // Adjust to your needs
        width: "100%",
        height: "80vh", // Adjust to your preferred height
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderRadius: "8px"
    }}>
        <img
            src={shopImageUrl}
            alt="Your Image"
            style={{
                width: "175px", // Adjust to your preferred size
                height: "175px", // Adjust to your preferred size
                borderRadius: "20%", // Clip as a circle
                objectFit: "cover",
                marginBottom: "12px"
            }}
        />
        <LoopIcon
            sx={{
                animation: "spin 2s linear infinite",
                "@keyframes spin": {
                    "0%": {
                        transform: "rotate(360deg)",
                    },
                    "100%": {
                        transform: "rotate(0deg)",
                    },
                },
                fontSize: "24px",
                color: "grey"
            }}
        />
    </Box>
}

export default LoadingCenter;