import {
    Container,
    Grid,
    Box,
    Avatar,
    Typography,
    Button,
} from "@mui/material";

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import { useSelector, useDispatch } from 'react-redux';
import DishCard from "./../components/DishCard";

import {
    // thunks
    // selectors
    selectSearchQuery,
    selectSearchResult,
    // actions
    updateSearchQuery
} from './../../../../features/menu/menuSlice';

const SearchBar = () => {
    // core
    const dispatch = useDispatch();

    // internal state
    const [placeholder, setPlaceHolder] = useState("Search menu");
    // const [searchQuery, setSearchQuery] = useState('');

    // selectors
    const search_results = useSelector(selectSearchResult);
    const search_query = useSelector(selectSearchQuery);


    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };


    const handleSearch = (event) => {
        // You can access the search query using event.target.value
        const search_query = event.target.value;
        console.log(search_query);
        //setSearchQuery(search_query);
        dispatch(updateSearchQuery({ search_query }));
    };

    const handleClear = () => {
        //setSearchQuery('');
        // onSearch('');
        dispatch(updateSearchQuery({ search_query: "" }));
    };


    return (
        <Box>
            <TextField
                placeholder={placeholder || 'Search...'}
                onChange={handleSearch}
                value={search_query}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        search_query && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClear}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    ),
                    sx: { borderRadius: 8 }
                }}
                fullWidth
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            {search_results.length > 0  ? <Container sx={{ p:1, m:1  }}> <Typography variant="body1" fontWeight="bold" component="span">{ search_query }</Typography>  ({search_results.length}) ပွဲ တွေ့သည်။</Container> : ""}
            {search_results.length == 0 && isFocused ? <Container  sx={{ p:1, m:1 }}>No dish available</Container> : ""}
            {search_results.map((dish) => (
                <Grid item xs={12} key={dish.id}>
                    {/* ဒီ Field  က လိုအပ်တဲ့ ဟာတွေအကုန်လုံးကို spreator / object လိုက် ပေးလိုက်ရင်လည်း မဆိုးဘူး */}
                    {/* <DishCard dish={dish} imageUrl={dish.image_url} title={dish.name} subTitle={dish.description} price={dish.price} currency={dish.currency}/> */}
                    <DishCard dish={dish} />
                </Grid>
            ))}
        </Box>
    );
}

export default SearchBar;