// config.js
// internal network
export const  API_HOST = "https://qr.mmsoftware100.com";
// export const  API_HOST = "http://192.168.4.101/qrmenu/public";
// export const  API_HOST = "http://172.20.10.10/qrmenu/public";
//http://192.168.4.101
// export const  API_HOST = "http://localhost/qrmenu/public";
export const  FILE_HOST = API_HOST;
export const  API_VERSION = "/api/v1";
export const  API_ENDPOINT = API_HOST + API_VERSION;
export const  LOGIN_API_ENDPOINT = API_ENDPOINT + "/login";
export const  REGISTER_API_ENDPOINT = API_ENDPOINT + "/register";
export const  ARTICLE_API_ENDPOINT = API_ENDPOINT + "/articles";
export const  VOTE_API_ENDPOINT = API_ENDPOINT + "/votes";
export const  SHOP_NAME_API_ENDPOINT = API_ENDPOINT + "/qr-menu";
export const  ANALYTIC_API_ENDPOINT = API_ENDPOINT + "/analytic";