import PhoneAndroidOutlined from "@mui/icons-material/PhoneAndroidOutlined";
import { createSlice } from "@reduxjs/toolkit";

import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { AirTwoTone, AirplaneTicket, Checklist, ConfirmationNumber, MenuBook, MobileFriendly, QrCode, Scanner } from "@mui/icons-material";

const initialState2 = {
  languages: [
    {
      code: "mm",
      label: "မြန်မာ",
      icon: "/assets/icons/ic_flag_mm.svg",
    },
    {
      code: "en",
      label: "English",
      icon: "/assets/icons/ic_flag_en.svg",
    },
  ],
  currentLanguage: {
    code: "en",
    label: "English",
    icon: "/assets/icons/ic_flag_en.svg",
  },
  en: {
    contactUs: "Contact Us",
    title: "MM Software 100​",
    nav: {
      home: "Home",
      services: "Services",
      projects: "Projects",
      contact: "Contact",
    },
    intro: {
      title: "Bringing Your Vision to Life",
      description:
        "Since 2019, we've been creating successful apps for our clients.You focus on your ideas, products, and business plan, and we'll handle the tech and development. Our expertise lies in Personal Apps, Real-time Chat, and Management Systems. ",
      image: "./assets/images/intro-section.png",
      url: "",
    },
    services: {
      title: "Our Development Services",
      services: [
        {
          title: "App Creation",
          icon: <PhoneAndroidOutlinedIcon />,
        },
        {
          title: "Web Based Solution",
          icon: <InsertLinkOutlinedIcon />,
        },
        {
          title: "Tech Support",
          icon: <DevicesOutlinedIcon />,
        },
        {
          title: "Maintence Care",
          icon: <BuildOutlinedIcon />,
        },
      ],
    },
    projects: {
      title: "Our Projects",
      projects: [
        {
          title: "QR Menu System",
          description:
            "The QR Menu system simplifies dining with instant access to menus through QR code scans. This touchless solution prioritizes safety and efficiency, providing a streamlined and modern dining experience.",
          image: "./assets/images/qr-menu.png",
          url: "",
        },
        {
          title: "Parcel Solution",
          description:
            "Parcel Solution simplifies logistics, ensuring efficient and secure deliveries. Streamlining the shipping process, it offers businesses a reliable and effective tool for managing parcels seamlessly. Optimize your delivery operations with Parcel Solution's advanced features.",
          image: "./assets/images/project-one.png",
          url: "",
        },
        {
          title: "Pandora App",
          description:
            "Discover a vibrant online community with our app, reminiscent of Reddit. Engage in diverse discussions, share your thoughts, and explore a plethora of topics. Stay connected and informed in a dynamic and user-friendly platform that fosters meaningful conversations.",
          image: "./assets/images/pandora.webp",
          url: "",
        },
        {
          title: "Customer Champion App",
          description:
            "The Customer Champion app is your dedicated tool for delivering exceptional customer service. With streamlined communication features, it empowers businesses to engage with customers, address concerns, and build lasting relationships. Elevate your customer support experience with the Customer Champion app.",
          image: "./assets/images/customer-champion.avif",
          url: "",
        },
      ],
    },
    contacts: {
      title: "Contact Us",
      contacts: [
        {
          title: "Email",
          description: "admin@email.com",
          image: "email.png",
          url: "mailto:admin@mmsoftware100.com",
        },
        {
          title: "Phone",
          description: "09963170133",
          image: "phone.png",
          url: "tel:+959963170133",
        },
        {
          title: "Viber",
          description: "09963170133",
          image: "viber.png",
          url: "viber://chat?number=+959963170133",
        },
        {
          title: "Messenger",
          description: "Mm Software 100",
          image: "messenger.png",
          url: "https://fb.me/mmsoftware100",
        },
      ],
    },
  },
  mm: {
    contactUs: "ဆက်သွယ်ရန်",
    title: "ဆော့ဝဲ (၁၀၀)​",
    nav: {
      home: "ပင်မစာမျက်နှာ",
      services: "ဝန်ဆောင်မှုများ",
      projects: "ပရောဂျက်များ",
      contact: "ဆက်သွယ်ရန်",
    },
    intro: {
      title: "စိတ်ကူးမှသည် ထုတ်ကုန်ဆီသို့",
      description:
        "နွေပူလာရင် အတော်လေး နေရခက်တာပါ။ နေပူချိန် အရမ်းပြင်းတဲ့အခါ နေ့ခင်းဘက်မှာ ပူသလို ညဘက်တွေမှာလည်း အရမ်းပူပါတယ်။ ဒီလို ပူပြင်းနေချိန်မှာ ကျန်းမာပြီး ခန္ဓာကိုယ်က အပူချိန်ကို ခံနိုင်စေဖို့ စားပေးသင့်တဲ့ အသီးအနှံလေးတွေက သပ်သပ်ကို ရှိတာပါ။ ဒီတော့ နွေပူပူမှာ စားပေးသင့်တဲ့ ဟင်းသီးဟင်းရွက်လေးတွေ က ဘာတွေများ ဖြစ်မလဲဆိုတာကို ဆောင်းပါးလေးထဲမှာ ဖတ်ကြည့်စေချင်ပါတယ်။",
      image: "./assets/images/intro-section.png",
      url: "",
    },
    services: {
      title: "ကျွန်တော်တို့ရဲ့ ဝန်ဆောင်မှုများ",
      services: [
        {
          title: "မိုဘိုင်းအက်ပ် ရေးသားခြင်း",
          icon: <PhoneAndroidOutlinedIcon />,
        },
        {
          title: "ဝဘ်ဆိုက် ရေးသားခြင်း",
          icon: <InsertLinkOutlinedIcon />,
        },
        {
          title: "နည်းပညာ အကူအညီ",
          icon: <DevicesOutlinedIcon />,
        },
        {
          title: "ပရောဂျက်",
          icon: <BuildOutlinedIcon />,
        },
      ],
    },
    projects: {
      title: "ကျွန်တော်တို့ရဲ့ ပရောဂျက်များ",
      projects: [
        {
          title: "ဒစ်ဂျစ်တယ် QR မီနူးစနစ်",
          description: "QR စကန်ဖတ်ပြီး ဟင်းပွဲ မီနူး ကြည့်ရတဲ့စနစ်",
          image: "./assets/images/qr-menu.png",
          url: "",
        },
        {
          title: "Parcel Solution",
          description:
            "Parcel Solution simplifies logistics, ensuring efficient and secure deliveries. Streamlining the shipping process, it offers businesses a reliable and effective tool for managing parcels seamlessly. Optimize your delivery operations with Parcel Solution's advanced features.",
          image: "./assets/images/project-one.png",
          url: "",
        },
        {
          title: "Pandora App",
          description:
            "Discover a vibrant online community with our app, reminiscent of Reddit. Engage in diverse discussions, share your thoughts, and explore a plethora of topics. Stay connected and informed in a dynamic and user-friendly platform that fosters meaningful conversations.",
          image: "./assets/images/pandora.webp",
          url: "",
        },
        {
          title: "Customer Champion App",
          description:
            "The Customer Champion app is your dedicated tool for delivering exceptional customer service. With streamlined communication features, it empowers businesses to engage with customers, address concerns, and build lasting relationships. Elevate your customer support experience with the Customer Champion app.",
          image: "./assets/images/customer-champion.avif",
          url: "",
        },
      ],
    },
    contacts: {
      title: "ဆက်သွယ်ရန်",
      contacts: [
        {
          title: "အီးမေးလ််",
          description: "admin@email.com",
          image: "email.png",
          url: "mailto:admin@mmsoftware100.com",
        },
        {
          title: "ဖုန်း",
          description: "09963170133",
          image: "phone.png",
          url: "tel:+959963170133",
        },
        {
          title: "ဗိုက်ဘာ",
          description: "09963170133",
          image: "viber.png",
          url: "viber://chat?number=+959963170133",
        },
        {
          title: "မက်ဆင်ဂျာ",
          description: "Mm Software 100",
          image: "messenger.png",
          url: "https://fb.me/mmsoftware100",
        },
      ],
    },
  },
};

const initialState = {
  languages: [
    {
      code: "mm",
      label: "မြန်မာ",
      icon: "/assets/icons/ic_flag_mm.svg",
    },
    {
      code: "en",
      label: "English",
      icon: "/assets/icons/ic_flag_en.svg",
    },
  ],
  currentLanguage: {
    code: "mm",
    label: "မြန်မာ",
    icon: "/assets/icons/ic_flag_mm.svg",
  },
  en: {
    contactUs: "Contact Us",
    title: "MM QR Menu​",
    nav: {
      home: "Home",
      services: "Features",
      projects: "Benefits",
      contact: "Contact",
    },
    intro: {
      title: "Your Quick Guide to Delicious Delights",
      description:
        "Welcome to our innovative dining experience where convenience meets culinary delight! Introducing our QR menu, a modern twist on the traditional dining menu. Embrace the future of gastronomy as you embark on a seamless journey through our offerings with just a scan.",
      image: "./assets/images/intro-section.png",
      url: "",
    },
    services: {
      title: "Features",
      services: [
        {
          title: "Scan QR",
          icon: <QrCode />,
        },
        {
          title: "See Menu",
          icon: <MenuBook />,
        },
        {
          title: "Add To Cart",
          icon: <Checklist />,
        },
        {
          title: "Confrim Order",
          icon: <MobileFriendly />,
        },
      ],
    },
    projects: {
      title: "Benefits",
      projects: [
        {
          title: "Cost-Efficiency",
          description:
            "Implementing a QR menu can significantly reduce printing costs associated with traditional paper menus. Owners can avoid the recurring expenses of updating and reprinting menus whenever there are changes in pricing, menu items, or promotions. With a QR menu, updates can be done digitally, eliminating the need for constant physical reprints and saving the business money in the long run.",
          // image: "./assets/images/qr-menu.png",

          image: "./assets/images/ten-menu-preview.png",
          url: "",
        },
        {
          title: "Real-Time Updates and Flexibility",
          description:
            "QR menus enable owners to make instant updates to their menu offerings, prices, and promotions in real-time. This flexibility allows for quick adjustments based on factors such as ingredient availability, seasonal changes, or market trends. Owners can adapt their menu swiftly to meet customer demands, experiment with new dishes, or respond promptly to feedback without the delays associated with traditional printed menus.",
          image: "./assets/images/project-one.jpg",
          url: "",
        },
        {
          title: "Enhanced Customer Engagement and Analytics",
          description:
            "QR menus provide an opportunity for owners to collect valuable data on customer preferences and behavior. By integrating analytics tools, owners can gain insights into popular dishes, peak ordering times, and customer demographics. This data can inform strategic decisions, such as optimizing the menu, adjusting pricing strategies, or planning targeted marketing campaigns to enhance customer satisfaction and boost overall business performance.",
          image: "./assets/images/analytic.jpg",
          url: "",
        },
        {
          title: "Improved Operational Efficiency",
          description:
            "QR menus streamline the ordering process, reducing the workload on both staff and kitchen operations. Customers can easily browse the menu, place orders, and make payments directly from their smartphones, minimizing the need for extensive interaction with waitstaff. This efficiency allows owners to optimize staffing levels, reduce wait times, and enhance the overall dining experience. Additionally, the digital nature of QR menus can integrate with point-of-sale (POS) systems, simplifying order processing and inventory management for the business.",
          image: "./assets/images/customer-champion.avif",
          url: "",
        },
      ],
    },
    contacts: {
      title: "Contact Us",
      contacts: [
        {
          title: "Email",
          description: "contact@mmqrmenu.com",
          image: "email.png",
          url: "mailto:contact@mmqrmenu.com",
        },
        {
          title: "Phone",
          description: "09963170133",
          image: "phone.png",
          url: "tel:+959963170133",
        },
        {
          title: "Viber",
          description: "09963170133",
          image: "viber.png",
          url: "viber://chat?number=+959963170133",
        },
        {
          title: "Messenger",
          description: "Mm QR Menu",
          image: "messenger.png",
          url: "https://m.me/mmqrmenu",
        },
      ],
    },
  },
  mm: {
    contactUs: "ဆက်သွယ်ရန်",
    title: "မြန်မာ ဒစ်ဂျစ်တယ် မီနူး​",
    nav: {
      home: "ပင်မစာမျက်နှာ",
      services: "ဝန်ဆောင်မှုများ",
      projects: "အကျိုးကျေးဇူးများ",
      contact: "ဆက်သွယ်ရန်",
    },
    intro: {
      title: "စာရွက်မှသည် ဒစ်ဂျစ်တယ်မီနူးဆီ",
      description:
        "ကိုယ့်ဆိုင်ကို လာတဲ့ ဖောက်သည်တွေ အတွက် ခေတ်ဆန်တဲ့ အတွေ့အကြုံကိုရမယ်။ လွယ်ကူ ရိုးရှင်းမယ်။ ဆိုင်ရှင်အနေနဲ့ Menu နဲ့ ပက်သက်တဲ့ ကုန်ကျစရိတ်ကို ထပ်ဝက်မက လျော့ချနိုင်မယ်။ဆိုင်မှာလည်း ဈေးကြီးတဲ့ စက်ပစ္စည်း‌တွေ ဝယ်တပ်စရာ မလိုသလို ဖောက်သည်တွေ အနေနဲ့လည်း ဘယ် app ကိုမှာ ဒေါင်းထားစရာမလို။",
      image: "./assets/images/intro-section.png",
      url: "",
    },
    services: {
      title: "ရိုးရှင်းတဲ့ အဆင့်များ",
      services: [
        {
          title: "စကန်ဖတ်ပါ",
          icon: <QrCode />,
        },
        {
          title: "မီနူးကြည့်ပါ",
          icon: <MenuBook />,
        },
        {
          title: "စိတ်ကြိုက်ရွေးပါ",
          icon: <Checklist />,
        },
        {
          title: "အော်ဒါတင်ပါ",
          icon: <MobileFriendly />,
        },
      ],
    },
    projects: {
      title: "အကျိုးကျေးဇူးများ",
      projects: [
        {
          title: "ကုန်ကျစရိတ် သက်သာ",
          description:
            "မီနူးစာအုပ်တွေ ပရင့်ထုတ်တဲ့ ကုန်ကျစရိတ်ရဲ့ တစ်ဝက်တောင် မရှိ။ မီနူးအသစ်တွေ ပြင်တိုင်း မီနူးစာအုပ် အသစ်ထပ်ထုတ်ရတဲ့ ကုန်ကျစရိတ်ကို မေ့လိုက်လို့ရပြီ။",
            //image: "./assets/images/qr-menu.png",
            //image: "./assets/images/menu-demo.jpg",
            image: "./assets/images/ten-menu-preview.png",
          url: "",
        },
        {
          title: "အချိန်နဲ့တပြေးညီ ပြင်ဆင်ဖြည့်စွက်",
          description:
            "မီနူး အသစ်ထည့်ချင်သလား? ကလစ်တချက်နှိပ်တာနဲ့ ပြီးတယ်။ စျေးနှုန်းတွေ ပြင်ချင်သလား? စျေးနှုန်းတိတ်နဲ့ လိုက်ကပ်ရတဲ့ ခေတ်ကို မေ့လိုက်ပါတော့၊ ကလစ်တစ်ချက်နဲ့ ပြီးတယ်။ ချက်ခြင်းပြင် ချက်ခြင်းမြင်နိုင်တဲ့ ဒစ်ဂျစ်တယ် မီနူး။",
          image: "./assets/images/project-one.jpg",
          url: "",
        },
        {
          title: "ဒေတာတွေက ပြောတဲ့ သင့်ဆိုင်အကြောင်း",
          description:
            "ဖောက်သည်တွေ ဘယ်ဟင်းပွဲကို အကြည့်များတယ်၊ ဘယ်အချိန်မှာ ဆိုင်လာထိုင်တာ များပြီး ၊​ ဘယ်အချိန်မှာ ပါစယ်ထုတ်ကြတယ်။ ဖောက်သည်တွေရဲ့ အကြိုက်ကို တိတိကျကျ ဖော်ပြပေးနိုင်မယ့် Dashboard.",
          image: "./assets/images/analytic.jpg",
          url: "",
        },
        {
          title: "အော်ဒါစနစ်",
          description:
            "စကားတစ်ခွန်းမှ ပြောစရာမလိုပဲ ဖောက်သည်ကိုယ်တိုင် အော်ဒါတင်နိုင်တာမို့ ဆိတ်ငြိမ်တဲ့ ပတ်ဝန်းကျင်ကို ရနိုင်မှာပါ။ ဖောက်သည် နဲ့ စားပွဲထိုးနဲ့ ထိတွေ့မှု အနည်းဆုံးနဲ့ အော်ဒါအမြန်ဆုံး မှာနိုင်တဲ့ စနစ်။ ",
          image: "./assets/images/customer-champion.avif",
          url: "",
        },
      ],
    },
    contacts: {
      title: "ဆက်သွယ်ရန်",
      contacts: [
        {
          title: "အီးမေးလ််",
          description: "contact@mmqrmenu.com",
          image: "email.png",
          url: "mailto:contact@mmqrmenu.com",
        },
        {
          title: "ဖုန်း",
          description: "09963170133",
          image: "phone.png",
          url: "tel:+959963170133",
        },
        {
          title: "ဗိုက်ဘာ",
          description: "09963170133",
          image: "viber.png",
          url: "viber://chat?number=+959963170133",
        },
        {
          title: "မက်ဆင်ဂျာ",
          description: "Mm Software 100",
          image: "messenger.png",
          url: "https://m.me/mmqrmenu",
        },
      ],
    },
  },
};

export const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
  },
});

export const { setCurrentLanguage } = landingSlice.actions;

export const selectTitle = (state) =>
  state.landing[state.landing.currentLanguage.code].title;
export const selectNav = (state) =>
  state.landing[state.landing.currentLanguage.code].nav;
export const selectLanguages = (state) => state.landing.languages;
export const selectCurrentLanguage = (state) => state.landing.currentLanguage;
export const selectIntro = (state) =>
  state.landing[state.landing.currentLanguage.code].intro;
export const selectServices = (state) =>
  state.landing[state.landing.currentLanguage.code].services;
export const selectProjects = (state) =>
  state.landing[state.landing.currentLanguage.code].projects;
export const selectContacts = (state) =>
  state.landing[state.landing.currentLanguage.code].contacts;

export const selectContactUs = (state) =>
  state.landing[state.landing.currentLanguage.code].contactUs;

export default landingSlice.reducer;
