import {
    Container,
    Grid,
    Box,
    Avatar,
    Typography,
    Button,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import {
    // selectors
    loggedInUser,
    loginStatus
} from './../../../features/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import React, { useEffect, useRef, useState } from 'react';


import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { useParams } from "react-router-dom";

import {
    // thunks
    selectArticlesAsyncThunk,
    // selectors
    selectArticles,
    selectArticlesPage
} from './../../../features/articles/articleSlice';

import Heading from "./components/Heading";
import Title from "./components/Title";
import SubInfo from "./components/SubInfo";
import CategoryList from "./components/CategoryList";
import SearchBar from "./components/SearchBar";
import MenuCard from "./components/MenuCard";



import {
    // thunks
    selectMenuByShopNameAsyncThunk,
    // selectors
    selectShopInfo,
    selectShopStatus,
    selectCategories,
    selectSelectedMenus,
    // actions
    updateSelectedMenu,
    updateSelectedCategory,
    selectRecommendedItemList
} from './../../../features/menu/menuSlice';
import LoadingCenter from "./components/LoadingCenter";
import HorizontalCardList from "./components/HorizontalCardList";


// sample state data

const background_image_url = "https://oddmenu.com/_nuxt/img/default-bg1.ccc3227.svg";
const shop_image_url = "https://dyj6gt4964deb.cloudfront.net/images/8866102348321223.jpeg";


const title = "Astro Brew House";
const address = "3090 Khatta(8) St. Ottarathiri";
const phone = "09441177996";
const wifi = "wifiP@ssw0rd";
const menus = ["Main Menu", "Bar", "Cafe"];
const categoryImageUrl = "https://dyj6gt4964deb.cloudfront.net/images/4874660711012869.jpeg";
const categoryTitle = "BREAKFAST";
const categoryTwoImageUrl = "https://dyj6gt4964deb.cloudfront.net/images/d14e2392-aa27-47f8-849f-d9d1f9930ebc.jpeg";
const categoryTwoTitle = "LIGHT FOOD";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




const Box1 = () => {
    return (
        <Paper style={{ width: 100, height: 100, backgroundColor: 'red' }}>
            Box 1
        </Paper>
    );
};

const Box2 = () => {
    return (
        <Paper style={{ width: 100, height: 100, backgroundColor: 'blue' }}>
            Box 2
        </Paper>
    );
};




// Example data
const cardData = [
    {
        title: 'Card 1',
        description: 'Description for Card 1',
        image: 'https://placekitten.com/200/140', // Replace with your image URL
    },
    {
        title: 'Card 2',
        description: 'Description for Card 2',
        image: 'https://placekitten.com/200/140', // Replace with your image URL
    },
    {
        title: 'Card 2',
        description: 'Description for Card 2',
        image: 'https://placekitten.com/200/140', // Replace with your image URL
    },
];



const MenuTwoHomePage = () => {
    // core
    const dispatch = useDispatch();

    // selector hooks

    // route parameter
    let { name } = useParams();
    // shop section

    const shopInfo = useSelector(selectShopInfo);
    const shopStatus = useSelector(selectShopStatus);
    const categoryList = useSelector(selectCategories);
    const menuList = useSelector(selectSelectedMenus);

    const commendedItemList = useSelector(selectRecommendedItemList);

    useEffect(() => {
        console.log("shop name is");
        console.log(name);
        const shop_name = name ?? "shop-one";
        console.log(shop_name);
        dispatch(selectMenuByShopNameAsyncThunk({ shop_name }));
    }, []); // Include history as a dependency



    return (
        <div>
            {shopStatus == "loading" ? <LoadingCenter shopImageUrl={shop_image_url} backgroundImageUrl={background_image_url} /> :
                <div style={{

                    display: 'flex',
                    justifyContent: 'center',
                    // height: '100vh',
                    padding: '8px',
                    border: "1px solid transparent"
                }}>

                    <Box sx={{
                        flexGrow: 1,
                        maxWidth: 'sm',

                        padding: '16px',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add boxShadow for elevation
                    }}>

                        <Grid container spacing={2}>


                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant="h4" component="h2" sx={{ color: 'black' }}>
                                    {shopInfo.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant="span" sx={{ color: 'black' }}>
                                    The Most Popular Items
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <HorizontalCardList itemList={commendedItemList}/>
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant="span" sx={{ color: 'black' }}>
                                    Choose your menu plz.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <CategoryList categories={categoryList} />
                            </Grid>
                            <Grid item xs={12}>
                                <SearchBar />
                            </Grid>

                            {/* need to map */}
                            {menuList.length == 0 ? <Container sx={{ p: 1, m: 1 }}>No menu available</Container> : ""}
                            {menuList.map((menu) => (
                                <Grid item xs={12} key={menu.id} >
                                    <MenuCard id={menu.id} menuImageUrl={menu.background_image_url} title={menu.name} />
                                </Grid>
                            ))}
                            {/* <Grid item xs={12}>
                        <CategoryCard categoryImageUrl={categoryImageUrl} title={categoryTitle} />
                    </Grid>
                    <Grid item xs={12}>
                        <CategoryCard categoryImageUrl={categoryTwoImageUrl} title={categoryTwoTitle} />
                    </Grid>
                    <Grid item xs={12}>
                        <CategoryCard categoryImageUrl={categoryImageUrl} title={categoryTitle} />
                    </Grid> */}

                            {/* <Grid item xs={8}>
                        <Item>xs=8</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>xs=4</Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>xs=12</Item>
                    </Grid> */}
                        </Grid>
                    </Box>
                </div>
            }
        </div>
    );
}
export default MenuTwoHomePage;