import {
    Container,
    Grid,
    Box,
    Avatar,
    Typography,
    Button,
} from "@mui/material";


const DishCard = ({ dish }) => {

    const {  background_image_url, name, description, price, currency } = dish;

    return <Box sx={{
        border: "1px solid transparent",
        mb: 1
    }}>
        <Grid container>
            <Grid item xs={12}>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    paddingBottom: '56.25%', // 16:9 aspect ratio (9 / 16 * 100%)
                    marginBottom: '8px'
                }}>
                      
                    <img
                        src={background_image_url}
                        alt="Your Image"
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            objectFit: "cover",
                            borderRadius: "24px",

                        }}
                    />
                </div>
            </Grid>
        </Grid>

        {/* <img
            src={imageUrl}
            alt="Your Image"
            style={{
                width: "100%", // Adjust to your preferred size

                objectFit: "cover",
                borderRadius: "24px",
                marginBottom: '8px'
            }}
        /> */}
        <Typography
            variant="subtitle2"
            sx={{
                color: 'rgba(0, 0, 0, 0.9)', // Black with 50% opacity
                fontSize: '16px', // Set the font size to 16 pixels
                marginBottom: '8px'
            }}
            component="h6">
            {name} 
        </Typography>
        <Typography
            variant="subtitle2"
            sx={{
                color: 'gray', // Set the font color to gray 
                fontSize: '12px', // Set the font size to 16 pixels
                marginBottom: '8px'
            }}
            component="h6">
            {description}
        </Typography>
        <Typography
            variant="subtitle2"
            sx={{
                color: 'black', // Set the font color to gray 
                fontSize: '18px', // Set the font size to 16 pixels
                marginBottom: '8px',
                fontWeight: 'bold', // Set the text to be bold
            }} component="h6">
            {price} {currency}
        </Typography>
    </Box>
}

export default DishCard;