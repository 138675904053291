import { Button, Container, Grid, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import { useSelector } from "react-redux";
import { selectServices } from "../../../../../features/landing/landingSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ServiceCard = ({ icon, name }) => {
  // Default styles for the icon
  const defaultIconStyle = {
    fontSize: 72,
    color: 'green'
    // backgroundColor: "red",
    // Add any other default styles you want
  };

  const styledIcon = React.cloneElement(icon, {
    sx: { ...defaultIconStyle, ...(icon.props.sx || {}) },
  });

  return (
    <Grid item xs={12} sm={12} md={3} textAlign={"center"}>
      <Container sx={{ border: "2px solid lightgrey", padding:'20px' }}>
        {styledIcon}
        <Typography p={2} component={'h2'} sx={{fontWeight:'bold'}} fontFamily={"MyanmarPhetsot"}> {name} </Typography>
      </Container>
    </Grid>
  );
};

function OurServicesSection() {
  const {title, services } = useSelector(selectServices);
  return (
    <>
      <Grid container spacing={2} id='services'>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h4" p={10} fontFamily={"MyanmarPhetsot"}>{title} </Typography>
        </Grid>
        {/* list of card */}
        { services.map((service) => <ServiceCard  icon={service.icon} name={service.title}/>)}
        {/* <ServiceCard icon={<PhoneAndroidOutlinedIcon />} name="App Creation" />
        <ServiceCard icon={<DevicesOutlinedIcon />} name="Web Based Solution" />
        <ServiceCard icon={<InsertLinkOutlinedIcon />} name="Tech Support" />
        <ServiceCard icon={<BuildOutlinedIcon />} name="Maintenance Care" /> */}
      </Grid>
    </>
  );
}

export default OurServicesSection;
