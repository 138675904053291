import axios from "axios";

import { ANALYTIC_API_ENDPOINT } from "./../../config/config";

// A mock function to mimic making an async request for data
export function createAnalyticApi({ click_type, click_id }) {
  console.log("createAnalyticApi");
  console.log({ click_type, click_id });

  /* ဒီမှာ Promise တစ်ခု return လုပ်ရမယ် */

  // Define the data you want to send in the request body (usually as an object)
  const postData = {
    click_type,
    click_id,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(ANALYTIC_API_ENDPOINT, postData)
      .then((response) => {
        setTimeout(() => resolve(response.data), 3000);
        //resolve(response.data);
      })
      .catch((error) => {
        setTimeout(() => reject(error), 3000);
        // reject(error);
      });
  });
}