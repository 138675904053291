import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loginApi, registerApi } from './userAPI';

// ဒါက Initial State
const initialState = {
  user: {
    "id": 11,
    "name": "Hello",
    "email": "hello234@email.com",
    "email_verified_at": null,
    "role_id": 3,
    "shops_count": 0,
    "active_subscription": {
      "id": 10,
      "user_id": 11,
      "plan_id": 1,
      "days_limit": 7,
      "start_date": "2023-11-06 00:00:00",
      "end_date": "2023-11-13 00:00:00",
      "status": 1,
      "created_at": "2023-11-06T09:32:01.000000Z",
      "updated_at": "2023-11-06T09:32:01.000000Z",
      "plan": {
        "id": 1,
        "name": "Free",
        "price": "0.00",
        "description": "Free plan with limited time and features",
        "created_at": "2023-11-06T01:48:24.000000Z",
        "updated_at": "2023-11-06T01:48:24.000000Z"
      }
    },
    "role": {
      "id": 3,
      "name": "client",
      "created_at": "2023-11-06T01:48:23.000000Z",
      "updated_at": "2023-11-06T01:48:23.000000Z"
    },
    "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTlmNGQ4MDk1MWVkNTk3YTc2YjM0NDA2MGU0ODUyZTFlZTkwZmIyMmU4OGY4ZWMxOTVmN2UxMGVlZTU4YzFmMmQzOGUyMjcwZmE2MWQ2NTIiLCJpYXQiOjE2OTkyNjMxMjEuNzUxODM2MDYxNDc3NjYxMTMyODEyNSwibmJmIjoxNjk5MjYzMTIxLjc1MTgzNjA2MTQ3NzY2MTEzMjgxMjUsImV4cCI6MTczMDg4NTUyMS43NDkwNzk5NDI3MDMyNDcwNzAzMTI1LCJzdWIiOiIxMSIsInNjb3BlcyI6W119.EaQU-lHcLdv6NR3Xo7v_YJDLdZdrSNjw8j3zI_b5y32lK84r4dVk4veuT4bkC2m0xz6pzCMz6xRAGgatl50Gd3YwpCYNK7mSrm-YrFA_Dtt_Og7hknfmmNUiKOQuG93b9RfiPt736vZDV1GYs-_oQwzTa8U72IECCvqhGDnK-5wF6Iz-06ycyN1zNBDZ6VOhAtwIdK0usMy8C-ddLDvwJiq7j7WX47IQCRwiXetXGY0Nrb4b4bWplWgJswJudmcooBP010GaGuSLlPSsO3yZnZYX061j_pgDcbNINY22R1H-haGcP-MyaMLhaAYO0g_jJFe6M_P_kObwxTaJkq7Wr9QNUrj3_tISla1UBJF7gn1BMEQyuxXOtfK7ixIO_CQ2_5uolWt17Dm_LPdRTL8OngOxCNeG4uUpng1eR9JOjXncsnVWs5uW3xWZnwaoWYQeDzxB4LzzgtbrdNjL2qvVSNg1SfdClxgItIir5cduprv4Rj-bonZEsEOUgLt-Uxg7LIw90KzXGAPSiD2RTTy-oOhRD3SlvTWIvMkkNl0jC6IVXYaLJYFeGKha2Re17MC-q4b_8cQFCJH1-Vjt2t_-avqyVqP_d8qyPst-E6rrDiyl_sA6XzFM6WKftpdMaLTkkzzP-FawRIaZPNIol430CfTMLBI_uMQ5Gz68wkkQMzs"
  },
  status: 'idle', // idle, loading, error , succeeded
  error: null,
  errors: {}, // for user register form validation errors
};

export const loginAsync = createAsyncThunk(
  'user/login',
  async ({ email, password }) => {
    console.log("loginAsync started");
    // ဒီ function စခေါ်တာနဲ့ pending action ကို dispatch လုပ်မယ်။
    // TODO: try catch this future 
    // ဒီမှာ မ catch ဘူးဆိုတာကဘာလဲ?
    // exception တက်ခဲ့ရင် error action ကို dispatch လုပ်မှာလား? ဒါလည်း မပြောတက်။
    // အခုလောလောဆယ် UI Component ကနေ Login ဝင်ချင်ရင် လုပ်စရာလိုတာက ဒီ​ Thunk Creator ကို Dispatch လုပ်ရုံပဲ
    // ဒါမျိုး dispatch(loginAsync({email, password}));

    const response = await loginApi({ email, password });
    console.log("loginAsync response");
    console.log(response);
    // The value we return becomes the `fulfilled` action payload
    // return ပြန်လိုက်တာနဲ့ ဒီ Thunk က fulfilled action ကို ထုတ်ပေး (dispatch) မယ်။
    return response.data;
  }
);


export const registerThunk = createAsyncThunk(
  'user/register',
  async ({ name, email, password, confirm_password }) => {
    const response = await registerApi({ name, email, password, confirm_password });
    console.log(response);
    return response.data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      return initialState;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
      //console.log("userSlice->setErrors");
      //console.log(localErrors);
    },
    setStatusIdle: (state) => {
      state.status = "idle";
    },
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        console.log("loginAsync.pending");
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        console.log("loginAsync.fulfilled");
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        console.log("loginAsync.rejected");
        state.status = 'error';
        state.error = action.error.message; // Store the error message
      })

      .addCase(registerThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(registerThunk.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message; // Store the error message
        state.errors = JSON.parse(action.error.message); // Store the error message
        console.log("registerThunk.rejected");
        console.log(action);
      });
  },
});

// actions

export const { logout, setErrors, setStatusIdle, increment, decrement, incrementByAmount } = userSlice.actions;
// selectors
export const loginStatus = (state) => state.user.status;
export const loginError = (state) => state.user.error;
export const loggedInUser = (state) => state.user.user;
export const selectUserRegistrationValidationError = (state) => state.user.errors;

// export reducer
// ဒါက combined reducer က ယူသုံးဖို့ ထင်ရဲ့။
// ဒါက Gloal State ထဲက ယူပြရမှာ။ 
// Combined Reducer ခေါ်မလားပဲ။
export default userSlice.reducer;