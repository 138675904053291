import axios from 'axios';
import { SHOP_NAME_API_ENDPOINT } from './../../config/config';

export function shopByNameSelectApi({shop_name }) {
    console.log("shopByNameSelectApi");
    console.log({shop_name  });
    return new Promise((resolve, reject) => {
        let endpoint = SHOP_NAME_API_ENDPOINT + "/" + shop_name;
        axios.get(endpoint)
            .then(response => {
                setTimeout(() => resolve(response.data), 500);
                //resolve(response.data);
            })
            .catch(error => {
                setTimeout(() => reject(error), 500);
                // reject(error);
            });
    });
}
