import { Add, Alarm, Delete, Remove } from "@mui/icons-material";
import {
  Container,
  Grid,
  Box,
  Avatar,
  Typography,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { increaseToCart, reduceFromCart } from "../../../../features/menu/menuSlice";

// "https://images.deliveryhero.io/image/fd-mm/Products/1178592.jpg?width=300&height=300"
const CartItemCard = ({ dish, cart_item }) => {
  const { item, quantity, item_id } = cart_item;
  const dispatch = useDispatch();

  const handleMinus = (item_id) => {
    dispatch(reduceFromCart({item_id}));
  }
  const handleAdd = (item_id) => {
    dispatch(increaseToCart({item_id}));
  }

  return (
    <>
      <Grid container sx={{ paddingTop:'24px', paddingLeft:'8px', paddingRight:'8px', paddingBottom:'24px', }}>
        {/* image */}
        <Grid item xs={2}>
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "100%", // 16:9 aspect ratio (9 / 16 * 100%)
              marginBottom: "8px",
            }}
          >
            <img
              src={item.background_image_url}
              alt="dish background"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </div>
        </Grid>
        {/* Name and Pricing */}
        <Grid item xs={10} sx={{ paddingLeft: "8px" }}>
          <Typography>{item.name}</Typography>
          {/* for extra note */}
          <Typography sx={{marginTop:'8px'}}>{item.extra_note}</Typography>
          <Grid container>
            {/* pricing */}
            <Grid item xs={6}>
              <Typography>
                {item.price} {item.currency}
              </Typography>
            </Grid>
            {/* button rows */}
            <Grid item xs={6} alignItems={"end"}>
              {/* <Container sx={{ backgroundColor: "grey", textAlign: "right" }}> */}
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Stack
                  direction={"row"}
                  alignItems="center"
                  sx={{
                    backgroundColor: "transparent",
                    border: "1px solid green",
                    borderRadius: "8px",
                  }}
                >
                  {/* remove from cart */}
                  {/* <IconButton color="secondary" aria-label="add an alarm" onClick={() => { handleMinus(item_id) }}>
                    <Delete />
                  </IconButton> */}

                  {/* decrease cart quanity */}
                  <IconButton color="secondary" aria-label="add an alarm"  onClick={() => { handleMinus(item_id) }}>
                     { quantity === 1 ? <Delete /> : <Remove /> }
                  </IconButton>
                  <Typography>{quantity}</Typography>

                  {/* increase cart quanity */}
                  <IconButton color="secondary" aria-label="add an alarm" onClick={() => { handleAdd(item_id) }}>
                    <Add />
                  </IconButton>
                </Stack>
              </Box>
              {/* </Container> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CartItemCard;
