import {
  Container,
  Grid,
  Box,
  Avatar,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  // thunks
  // selectors
  // actions
  updateSelectedMenu,
  updateSearchQuery,
} from "../../../../features/menu/menuSlice";

const MenuCard = ({ id, menuImageUrl, title }) => {
  // core
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (id) => {
    console.log("Box clicked!");
    // Add your click event handling logic here
    dispatch(updateSelectedMenu({ id }));
    dispatch(updateSearchQuery({ search_query: "" }));

    console.log(id);
    navigate("/dishes");
  };
  return (
    <Container
      onClick={() => handleClick(id)} // Attach the onClick event handler
      sx={{
        // Attach the onClick event handler
        border: "1px solid #000",
        backgroundImage: `url(${menuImageUrl})`, // Use template literals to reference the variable
        backgroundSize: "cover", // Adjust to your needs
        backgroundPosition: "center center", // Adjust to your needs
        width: "100%",
        height: "200px", // Adjust to your preferred height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 8,
      }}
    >
      <Box
        sx={{
          border: "1px solid #000",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: 8,
        }}
      >
        <Typography variant="h4" component="h2" sx={{ color: "white" }}>
          {title}
        </Typography>
      </Box>
    </Container>
  );
};

export default MenuCard;
