import {
  Container,
  Grid,
  Box,
  Avatar,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { createAnalyticAsyncThunk } from "../../../../features/analytic/analyticSlice";
import { addToCart } from "../../../../features/menu/menuSlice";

const DishCardV3 = ({ dish, even }) => {
  const { background_image_url, name, description, price, currency } = dish;

  // core
  const dispatch = useDispatch();

  // internal state
  const [menuDetailClicked, setMenuDetailClicked] = useState(false);

  const handleOnTap = (id) => {
    // show dialog
    setMenuDetailClicked(true);
    let click_type = "item";
    let click_id = id;
    dispatch(createAnalyticAsyncThunk({click_type, click_id}));
  };
  const handleClose = () => {
    setMenuDetailClicked(false);
  };

  const handleAddToCart = (id) => {
    handleClose();
    let click_type = "cart";
    let click_id = id;
    // add to cart reducer ကို ဘယ်လိုခေါ်မလဲ?

    // clear search
    dispatch(addToCart({ item_id: id }));
    // dispatch(createAnalyticAsyncThunk({click_type, click_id}));

  }

  return (
    <div>
      <Box
        onClick={() => handleOnTap(dish.id)}
        sx={{
          border: "1px solid tr",
          pt: 2,
          pb: 1,
          pl: 1,
          pr: 1,
          mb: 1,
          //backgroundColor: even ? "rgba(0,0,0,0.05)" : "transparent",
          backgroundColor:  "rgba(0,0,0,0.05)",
          borderRadius: "8px",
          // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add this line for box shadow
        }}
      >
        {/* <Container sx={{ display: "flex", flexWrap:true, flexDirection: "row" }}>
        <Container sx={{ flexBasis:'70%',flexWrap:true, display: "flex", flexDirection: "column",backgroundColor:'blue' }}>
          <Typography>Title</Typography>
          <Typography>DescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescription</Typography>
          <Typography>Price</Typography>
        </Container>
        <Container sx={{ flexBasis:'30%',  backgroundColor:'red'}}>
          <img
            src={background_image_url}
            alt="dish "
            style={{
              width: "100%", // Adjust to your preferred size
              objectFit: "cover",
              borderRadius: "24px",
              // marginBottom: "8px",
            }}
          />
        </Container>
      </Container> */}
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "rgba(0, 0, 0, 0.9)", // Black with 50% opacity
                fontSize: "16px", // Set the font size to 16 pixels
                marginBottom: "8px",
              }}
              component="h6"
            >
              {name}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "gray", // Set the font color to gray
                fontSize: "12px", // Set the font size to 16 pixels
                marginBottom: "8px",
              }}
              component="h6"
            >
              {description}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "black", // Set the font color to gray
                fontSize: "18px", // Set the font size to 16 pixels
                marginBottom: "8px",
                fontWeight: "bold", // Set the text to be bold
              }}
              component="h6"
            >
              {price} {currency}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                position: "relative",
                width: "100%",
                paddingBottom: "56.25%", // 16:9 aspect ratio (9 / 16 * 100%)
                marginBottom: "8px",
              }}
            >
              <img
                src={background_image_url}
                alt="dish background"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </div>
          </Grid>
        </Grid>

        {/* <img
            src={imageUrl}
            alt="Your Image"
            style={{
                width: "100%", // Adjust to your preferred size

                objectFit: "cover",
                borderRadius: "24px",
                marginBottom: '8px'
            }}
        /> */}
        {/* <Typography
        variant="subtitle2"
        sx={{
          color: "rgba(0, 0, 0, 0.9)", // Black with 50% opacity
          fontSize: "16px", // Set the font size to 16 pixels
          marginBottom: "8px",
        }}
        component="h6"
      >
        {name}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          color: "gray", // Set the font color to gray
          fontSize: "12px", // Set the font size to 16 pixels
          marginBottom: "8px",
        }}
        component="h6"
      >
        {description}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          color: "black", // Set the font color to gray
          fontSize: "18px", // Set the font size to 16 pixels
          marginBottom: "8px",
          fontWeight: "bold", // Set the text to be bold
        }}
        component="h6"
      >
        {price} {currency}
      </Typography> */}
      </Box>
      {/*  menu detail dialog */}
      <Dialog
        fullWidth={true}
        maxWidth={"xs"} // ------- 'sm'   'md'   'lg'   'xl'
        open={menuDetailClicked}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{name}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            {name}
          </DialogContentText> */}
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: "56.25%", // 16:9 aspect ratio (9 / 16 * 100%)
                  marginBottom: "8px",
                }}
              >
                <img
                  src={background_image_url}
                  alt="dish background"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "gray", // Set the font color to gray
                  fontSize: "12px", // Set the font size to 16 pixels
                  marginBottom: "8px",
                }}
                component="h6"
              >
                {description}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "black", // Set the font color to gray
                  fontSize: "18px", // Set the font size to 16 pixels
                  marginBottom: "8px",
                  fontWeight: "bold", // Set the text to be bold
                }}
                component="h6"
              >
                {price} {currency}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained"  sx={{marginBottom:'8px'}} onClick={() => handleAddToCart(dish.id)}>Add To Dish</Button>
              <Button fullWidth variant="outlined" onClick={handleClose}>Cancel</Button>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default DishCardV3;
