import {
  Container,
  Grid,
  Box,
  Avatar,
  Typography,
  Button,
  Card,
  Radio,
  Stack,
  Paper,
} from "@mui/material";
import Chip from "@mui/material/Chip";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";



import {
  // thunks
  // selectors
  // actions
  updateSelectedCategory,
  updateSearchQuery,
  updateSelectedMenu,
} from "../../../../../features/menu/menuSlice";
import {
  CheckBox,
  RadioButtonChecked,
  RadioButtonUnchecked,
  RadioOutlined,
} from "@mui/icons-material";
import { createAnalyticAsyncThunk } from "../../../../../features/analytic/analyticSlice";
import { useState } from "react";

/*
 လိုတာကတော့ Menu Object Array နဲ့ callback လုပ်ရမယ့် / dispatch လုပ်ရမယ့် Action ပဲ
 မရှုပ်ချင်ရင် Selector နဲ့တောင်ရတယ်။
*/
// menus is string array
export const MenuHorizontalList = ({ menus }) => {
  // core
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();


  // event hanlder
  const onTapHandler = (id) => {
    console.log("onTapHandler");
    console.log(id);
    dispatch(updateSelectedCategory({ id }));

    // clear search

    dispatch(updateSearchQuery({ search_query: "" }));
    // should we go back or not check
    // if
    const currentRoute = location.pathname;
    console.log(currentRoute);
    if (currentRoute === "/dishes") {
      // go back
      // navigate("/");
      navigate(-1);
    }
  };

  const menuCardClickHandler = (id) => {
    console.log("Box clicked!");
    // Add your click event handling logic here
    dispatch(updateSelectedMenu({ id }));
    dispatch(updateSearchQuery({ search_query: "" }));

    console.log(id);
    // navigate("/dishes");

    // disptach analytic event
    let click_type = "menu";
    let click_id = id;
    dispatch(createAnalyticAsyncThunk({click_type, click_id}));

  };


  return (
    // <Box style={{ overflowX: "auto", whiteSpace: "normal" }}>
    //   {menus.map((menu, index) => (
    //     <Container
    //       key={index}
    //       style={{ display: "inline-block", margin: "0 10px" }}
    //     >
    //       {menu.name}
    //     </Container>
    //   ))}
    // </Box>

    // {/* သူ့မှာပါတဲ့ children တွေရဲ့ အရွယ်အစား အပေါ် မူတည်ပြီး parent ထက်ပိုတဲ့ width ကို တောင်းနေတာ */}

    // <Box style={{ backgroundColor:'red', width:'100%', margin:'0px', padding:'0px', overflowX: "scroll"}}>
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        overflow: "auto",
        backgroundColor: "transparent",
        padding: "8px"
      }}
    >
      {menus.map((menu, index) => (
        <div style={{whiteSpace:'nowrap'}}>
          <Paper
            key={index}
            onClick={() => menuCardClickHandler(menu.id)}
            style={{
              // whiteSpace: "nowrap",
              display: "inline-block",
              margin: "0 10px",
              padding: "8px",
              backgroundColor: menu.selected ? "lightblue" : "white",
            }}
            elevation={2}
          >
            {menu.name}
          </Paper>
        </div>
      ))}

    </div>
    // </Box>
    // <Box>
    //   {menus.map((menu, index) => (
    //     <Container>{menu.name}</Container>
    //   ))}
    // </Box>
    // <Box sx={{ border: "1px solid transparent", padding:"8px", overflowX: "auto", whiteSpace: "nowrap"  }}>
    //   <Grid container spacing={2} justifyContent={"center"}>
    //     {menus.map((menu, index) => (
    //       <Grid item key={index}>
    //         <Container
    //           onClick={() => menuCardClickHandler(menu.id)}
    //           textAlign="center"
    //           // top-right and bottom-left corners
    //           style={{
    //             border: "1px solid #ddd",
    //             padding: "10px",
    //             borderRadius: '8px', // '0px 16px 0px 16px',
    //             backgroundColor: menu.selected ? "lightblue" : "white", // Set your desired background color
    //             cursor: "pointer", // Change cursor on hover
    //             display: "inline-block", // Make containers display inline
    //           }} // Optional: Add border and padding for styling
    //         >
    //           <Stack direction={"row"}>
    //             {/* <CheckBox sx={{marginRight:'8px', color: menu.selected ? 'black' : 'grey'}}/> */}
    //             {/* { menu.selected ? <RadioButtonChecked sx={{marginRight:'8px', color: 'black'}}/> : <RadioButtonUnchecked sx={{marginRight:'8px', color:  'grey'}} /> }  */}

    //             <Typography>{menu.name}</Typography>
    //           </Stack>
    //         </Container>
    //       </Grid>
    //     ))}
    //   </Grid>
    // </Box>
  );
};
