import { useState } from 'react';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentLanguage, selectLanguages, setCurrentLanguage } from '../../../../../features/landing/landingSlice';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'mm',
    label: 'မြန်မာ',
    icon: '/assets/icons/ic_flag_mm.svg',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  }
  /*
  {
    value: 'fr',
    label: 'French',
    icon: '/assets/icons/ic_flag_fr.svg',
  },
  */
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  // global state
  const dispatch = useDispatch();

  // const title = useSelector(selectTitle);
  const languages = useSelector(selectLanguages);
  const currentLanguage = useSelector(selectCurrentLanguage);
  // internal state 
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (language) => {
    setOpen(null);
    console.log("handleClose");
    console.log(language);
    if(language.code !== undefined){
      // dispatch
      console.log("dispatch setCurrentLanguage");
      dispatch(setCurrentLanguage(language));
      console.log(language.code);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <img src={currentLanguage.icon} alt={currentLanguage.label} />
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.code}
            selected={language.code === currentLanguage.code}
            onClick={() => handleClose(language)}
            sx={{ typography: 'body2', py: 1 }}
          >
            <Box component="img" alt={language.label} src={language.icon} sx={{ width: 28, mr: 2 }} />

            {language.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
