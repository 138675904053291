import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';


import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './presentation/Layout';
import NoPage from './presentation/pages/NoPage';
import LoginPage from './presentation/pages/auth/LoginPage';
import RegisterPage from './presentation/pages/auth/RegisterPage';
import ArticleListPage from './presentation/pages/article/ArticleListPage';
import ArticleLoadingPage from './presentation/pages/article/ArticleLoadingPage';
import ChatConversationPage from './presentation/pages/chat/ChatConversationPage';
import ArticleDetailPage from './presentation/pages/article/ArticleDetailPage';
import ForgotPasswordPage from './presentation/pages/auth/ForgotPasswordPage';
import ConfirmOtpPage from './presentation/pages/auth/ConfirmOtpPage';
import LogoutPage from './presentation/pages/auth/LogoutPage';
import FirebaseSignUpPage from './presentation/pages/firebase/FirebaseSignUpPage';
import FirebaseLoginPage from './presentation/pages/firebase/FirebaseLoginPage';
import FirebaseHomePage from './presentation/pages/firebase/FirebaseHomePage';
import MenuHomePage from './presentation/pages/qrmenu/MenuHomePage';
import MenuDishListPage from './presentation/pages/qrmenu/MenuDishListPage';
import LandingPage from './presentation/pages/landing/LandingPage';
import UserDashboardPage from './presentation/pages/auth/UserDashboardPage';
import MenuTwoHomePage from './presentation/pages/qrmenu/MenuTwoHomePage';
import MenuThreeHomePage from './presentation/pages/qrmenu/MenuThreeHomePage';
import CartListPage from './presentation/pages/qrmenu/CartListPage';
import HomePage from './presentation/pages/landing/home/home-page';




const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* <Route index element={<LandingPage />} /> */}
            <Route index element={<HomePage />} />
            <Route path="/v1/:name" element={<MenuHomePage />} /> 
            <Route path="/v2/:name" element={<MenuTwoHomePage />} />
            <Route path="/v3/:name" element={<MenuThreeHomePage />} />
            <Route path="/shop/:name" element={<MenuThreeHomePage />} />
            <Route path="/:name" element={<MenuThreeHomePage />} />
            <Route path="/:name/cart" element={<CartListPage />} />
            <Route path="/dishes" element={<MenuDishListPage />} />
            <Route path="/articles" element={<ArticleListPage />} />
            <Route path="/dashboard" element={<UserDashboardPage />} />
            <Route path="*" element={<NoPage />} />
            {/*
            <Route path="/firebase-signup" element={<FirebaseSignUpPage />} />
            <Route path="/firebase-login" element={<FirebaseLoginPage />} />
            <Route path="/firebase-home" element={<FirebaseHomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/confirm-otp" element={<ConfirmOtpPage />} />
            <Route path="/app" element={<App />} />
            <Route path="/article-loading" element={<ArticleLoadingPage />} />
            <Route path="/chat-conversation" element={<ChatConversationPage />} />
            <Route path="/articles/:id" element={<ArticleDetailPage />} />
            */}
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
